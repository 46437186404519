import PreguntaService from "@/api/secciones/preguntas";

const PreguntaStore = {
  state: {
    preguntaList: [],
    form: {},
  },
  mutations: {
    SET_PREGUNTA: (state, payload) => (state.form = payload),
    SET_PREGUNTA_LIST: (state, list) => (state.idiomaList = list),
  },
  actions: {
    async getPreguntaList({ dispatch }, { search, page, pageSize, seccion }) {
      const res = await PreguntaService.list(search, page, pageSize, seccion);

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    },
    setPregunta({ commit }, payload) {
      commit("SET_PREGUNTA", payload);
    },
    async savePregunta({ dispatch }, payload) {
      const res = payload.id
        ? await PreguntaService.edit(payload)
        : await PreguntaService.create(payload);

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    },
    async getPreguntaById({ dispatch }, { id }) {
      const res = await PreguntaService.getById({ id });
      let data = null;

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          data = await res.json();
      }

      return data;
    },
    setPreguntaList({ commit }, list) {
      commit("SET_PREGUNTA_LIST", list);
    },
    async deactivatePregunta({ dispatch }, { id }) {
      const res = await PreguntaService.deactivate({ id });

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    }
  },
  getters: {
    getPregunta: (state) => state.form,
    getPreguntaListState: (state) => state.idiomaList,
  },
};

export default PreguntaStore;
