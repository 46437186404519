<template>
  <div>
    <a
      class="text-blueGray-500 py-1 px-3"
      ref="btnDropdownRef"
      @click="toggleDropdown($event)"
    >
      <i class="fas fa-ellipsis-v"></i>
    </a>
    <teleport to="body">
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base float-left py-2 list-none text-left rounded shadow-lg min-w-48"
      style="z-index: 9999"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <a
        v-if="showEditBtn"
        href="javascript:void(0);"
        class="dropdown-extra-link text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 hover:bg-blueGray-100"
        @click.prevent.stop="dropdownPopoverShow=false & $emit('edit-row')"
      >
        {{ $t('btnLabels.edit') }}
      </a>
      <a
        v-if="showViewBtn"
        href="javascript:void(0);"
        class="dropdown-extra-link text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 hover:bg-blueGray-100"
        @click.prevent.stop="dropdownPopoverShow=false & $emit('view-row')"
      >
        {{ $t('btnLabels.view') }}
      </a>
      <a
        v-if="showDeleteBtn"
        href="javascript:void(0);"
        class="dropdown-extra-link text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 hover:bg-blueGray-100"
        @click.prevent.stop="dropdownPopoverShow=false & $emit('delete-row')"
      >
        {{ $t('btnLabels.delete') }}
      </a>
      <slot name="dropdown-extra-button"></slot>
    </div>
    </teleport>
  </div>
</template>
<script>
import { createPopper } from "@popperjs/core";

export default {
  props: {
    showEditBtn: {
      type: Boolean,
      default:true
    },
    showViewBtn: {
      type: Boolean,
      default:true
    },
    showDeleteBtn: {
      type: Boolean,
      default:true
    },
  },
  data() {
    return {
      dropdownPopoverShow: false,
    };
  },
  mounted() {
    // Agregar un event listener al documento
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    // Remover el event listener cuando se desmonte el componente
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
    handleClickOutside(event) {
      // Si el dropdown está abierto y el click no fue dentro del dropdown
      if (this.dropdownPopoverShow && 
          this.$refs.popoverDropdownRef && 
          !this.$refs.popoverDropdownRef.contains(event.target) &&
          !this.$refs.btnDropdownRef.contains(event.target)) {
        this.dropdownPopoverShow = false;
      }
    }
  },
  emits: ['edit-row', 'view-row', 'delete-row']
};
</script>
<style scoped>
::v-deep .dropdown-extra-link:hover {
  background-color: #f1f5f9 !important;
  font-weight: bold;
}
</style>