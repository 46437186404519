<template>
  <crud-base
    :title="$t('forms.seccion.title')"
    default-heigth="auto"
    :popup-data="popupData"
    :show-popup="showPopup"
    @cancel="
      popupData.type === 'info' ? (this.showPopup = false) : returnToList()
    "
    @add-register="save"
    @ok="
      popupData.type === 'info' || popupData.type === 'error'
        ? (this.showPopup = false)
        : returnToList()
    "
  >
    <template v-slot:form>
      <div class="flex-auto lg:px-2 py-2 pt-4">
        <div class="tabs mb-4">
          <button :class="{ active: activeTab === 'info' }" @click="activeTab = 'info'" class="tab-button">
            Información Básica
            <!-- {{ $t('forms.seccion.tabs.general') }} -->
          </button>
          <button :class="{ active: activeTab === 'preguntas' }"
            @click="verificarInformacionBasica() && saveOrUpdate()" 
            class="tab-button">
            <!-- {{ $t('forms.seccion.tabs.preguntas') }} -->
            Preguntas
          </button>
        </div>

        <!-- Tab content -->
        <div v-if="activeTab === 'info'">
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full lg:w-6/12 px-2">
              <custom-input
                label="DESCRIPCIÓN"
                :placeholder="$t('forms.seccion.fieldsPlaceholder.descripcion')"
                :disabled="isOnlyView"
                type="text"
                v-model="seccion.descripcion"
              />
            </div>
            <div class="w-full lg:w-6/12 px-2">
              <label
                class="w-full block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                {{ $t("forms.idiomas.title") }}
              </label>
              <idiomas-multiselect v-model="seccion.idioma" />
            </div>
          </div>
        </div>
        <div v-if="activeTab === 'preguntas'">
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <list-preguntas
                ref="listPreguntas"
                :seccion-id="seccion.id"
                :show-order-btn="true"
                @add-row="addQuestion"
                @edit-row="handleEditQuestion"
                @view-row="handleViewQuestion"
                @ordenar="openUpdateOrderModal"
              />
            </div>
          </div>
        </div>
      </div>
      <modal-pregunta
          :show-modal-pregunta="showModalPregunta"
          :is-edit.sync="isEdit"
          :is-only-view-modal="isOnlyViewModal"
          :pregunta-actual="modalForm"
          :pregunta-actual-id="preguntaActualId"
          :seccion="seccion"
          @close-modal="showModalPregunta = false && reloadListPreguntas()"
          @update-question="handleUpdateQuestion"
          @commit-question="handleCommitQuestion"
        />
      <update-order-modal
        :show="showUpdateOrderModal"
        :current-order="currentOrder"
        @close="showUpdateOrderModal = false"
        @update-order="actualizarOrdenPregunta"
      />
    </template>
  </crud-base>
</template>
<script>
import CrudBase from "@/components/Cards/CrudBase";
import CustomInput from "@/components/Inputs/CustomInput.vue";
import ModalPregunta from "@/components/Encuestas/ModalPregunta.vue";
import IdiomasMultiselect from "@/views/idiomas/IdiomasMultiselect";
import ListPreguntas from "@/views/secciones/ListPreguntas";
import { baseMixin } from "@/mixins/base";
import { mapActions, mapGetters } from "vuex";
import UpdateOrderModal from "@/components/Encuestas/UpdateOrderModal.vue";


export default {
  components: {
    CrudBase,
    CustomInput,
    ListPreguntas,
    ModalPregunta,
    IdiomasMultiselect,
    UpdateOrderModal
  },
  mixins: [baseMixin],
  data() {
    return {
      activeTab: 'info',
      module: "seccion",
      capitalizeModule: "Seccion",
      listName: "ListSecciones",
      seccion: {
        preguntas: [],
      },
      popupData: {
        show: false,
        type: '',
        title: '',
        message: ''
      },
      isOnlyViewModal: false,
      showPopup: false,
      searchQuery: "",
      showModalPregunta: false,
      preguntaActualId: null,
      isEdit: false,
      toUpdateIndex: -1,
      preguntasOriginales: [],
      modalForm: {},
      isChangeTab: false,
      showUpdateOrderModal: false,
      currentOrder: null,
      preguntaIdToUpdate: null,
      pregunta: null
    };
  },
  computed: {
    ...mapGetters(["getQuestionModalForm", "getTiposRespuesta"]),
    orderedPreguntas() {
      return [...this.seccion.preguntas].sort((a, b) => a.orden - b.orden);
    },
  },
  async created() {
    this.isLoading = !this.isLoading;
    this.setShowOverlay(this.isLoading);
    this.getPageTitle();

    this.$route.params.id ? await this.loadData() : this.initForm();

    this.isLoading = !this.isLoading;
    this.setShowOverlay(this.isLoading);
  },
  methods: {
    ...mapActions([
      "setModalForm",
      "saveSeccion",
      "setSeccion",
      "getSeccionById",
      "getTipoRespuestaList",
      "setShowOverlay",
      "savePregunta"
    ]),
    initForm() {
      this.seccion = {
        descripcion: null,
        idioma: null,
        preguntas: [],
      };
    },
    async loadData() {
      this.seccion = await this.getSeccionById({ id: this.$route.params.id });
    },
    verificarInformacionBasica() {
      if (this.seccion.descripcion && this.seccion.idioma) {
        return "preguntas";
      } else {
        this.popupData = {
          show: true,
          type: "info",
          title: "Información",
          message: "Debe completar la información básica de la sección para continuar",
        };
        this.showPopup = true;
        return "info";
      }
    },
    increaseOrder(index) {
      if (index > 0) {
        const temp = this.seccion.preguntas[index];
        this.seccion.preguntas[index] = this.seccion.preguntas[index - 1];
        this.seccion.preguntas[index - 1] = temp;
        this.actualizarOrden();
      }
    },
    decreaseOrder(index) {
      if (index < this.seccion.preguntas.length - 1) {
        const temp = this.seccion.preguntas[index];
        this.seccion.preguntas[index] = this.seccion.preguntas[index + 1];
        this.seccion.preguntas[index + 1] = temp;
        this.actualizarOrden();
      }
    },
    actualizarOrden() {
      this.seccion.preguntas.forEach((pregunta, index) => {
        pregunta.orden = index; // Actualiza el atributo de orden
      });
    },
    addQuestion() {
      this.isEdit = false;
      this.isOnlyViewModal = false;
      this.showModalPregunta = true;
      this.preguntaActualId = null;
      this.modalForm = {}
    },
    setInitModalForm() {
      return {
        descripcion: null,
        cerrada: false,
        multiple: false,
        beneficio: false,
        respuestas: [],
      };
    },
    handleSearch(query) {
      this.searchQuery = query;

      if (!query || query.trim() === "") {
        this.seccion.preguntas = [...this.preguntasOriginales];
      } else {
        this.seccion.preguntas = this.preguntasOriginales.filter((pregunta) =>
          pregunta.descripcion.toLowerCase().includes(query.toLowerCase())
        );
      }
      this.showModalPregunta = false;
    },
    async handleCommitQuestion(newQuestion) {
      const maxOrden = Math.max(
        0,
        ...this.seccion.preguntas.map((pregunta) => pregunta.orden || 0)
      );

      this.seccion.preguntas.push({
        ...newQuestion,
        orden: maxOrden + 1,
      });
      this.preguntasOriginales = [...this.seccion.preguntas];
      this.$forceUpdate();
    },
    handleEditQuestion(id) {
      this.preguntaActualId = id;
      this.toUpdateIndex = id;
      this.isEdit = true;
      this.isOnlyViewModal = false;
      this.showModalPregunta = true;
    },
    handleViewQuestion(id) {
      this.preguntaActualId = id;
      this.toUpdateIndex = id;
      this.isEdit = false;
      this.isOnlyViewModal = true;
      this.showModalPregunta = true;
    },
    handleUpdateQuestion(updatedQuestion) {
      this.seccion.preguntas.splice(this.toUpdateIndex, 1, {
        ...updatedQuestion,
      });
      this.$forceUpdate();
    },
    openUpdateOrderModal(row) {
      if (row) {
        this.pregunta = row
        this.currentOrder = row.orden;
        this.preguntaIdToUpdate = row.id;
        this.showUpdateOrderModal = true;
      }
    },
    async actualizarOrdenPregunta(nuevoOrden) {
      this.pregunta.orden = nuevoOrden;
      const res = await this.savePregunta(this.pregunta);
      console.log(res);

      if (res.ok) {
        this.setShowOverlay(false);

        this.popupData = {
          show: true,
          type: "success",
          title: this.$t("popup.success.title"),
          message: this.$t("popup.success.message")
        };
        this.showPopup = true;
      } else {
        this.setShowOverlay(false);
        this.popupData = {
          show: true,
          type: "error",
          title: this.$t("popup.error.title"),
          message: res
        };
        this.showPopup = true;
      }
    },
    async save() {
      try {
        this.setShowOverlay(true);
        this.seccion = {
          ...this.seccion,
          idioma: this.seccion?.idioma?.id
        };

        this.setSeccion(this.seccion);
        const res = await this.saveSeccion();
        const isOk = res.status >= 200 && res.status <= 300;
        
        if (res.ok) {
          const data = await res.json();
          
          this.setShowOverlay(false);
          this.seccion.id = isOk ? data.id : null;
  
          if (!this.isChangeTab) {
            this.popupData = {
              show: true,
              type: isOk ? "success" : "error",
              title: isOk
                ? this.$t("popup.success.title")
                : this.$t("popup.error.title"),
              message: isOk
                ? this.$t("popup.success.message")
                : data.non_field_errors[0],
            };
            this.showPopup = true;
          } else {
            this.activeTab = "preguntas";
          }
          this.isChangeTab = false;
        } else {
          const error = await res.json();
          let errMessage = null

          if(error) {
            errMessage = error?.descripcion && error.descripcion[0].includes('Sección with this Descripción already exists') ? "Ya existe una seccion con este nombre" : this.$t("popup.error.message");
          }
          this.setShowOverlay(false);
          this.popupData = {
            show: true,
            type: "error",
            title: this.$t("popup.error.title"),
            message: errMessage,
          };
          this.showPopup = true;
          this.activeTab = "info"
        }
        
      } catch (error) {
        const err = error.message || error;
        console.log(err);

        this.setShowOverlay(false);
        this.popupData = {
          show: true,
          type: "error",
          title: this.$t("popup.error.title"),
          message: err || this.$t("popup.error.message")
        };
        this.showPopup = true;
      }
    },
    async saveOrUpdate() {
      try {
        this.setShowOverlay(true);

        if (this.activeTab === "preguntas") {
          this.verificarInformacionBasica();
        } else {
          this.isChangeTab = true;
          !this.$route.params.id ? this.save() : this.activeTab = "preguntas";
        }
      } catch(err) {
        console.error(err);
      } finally {
        this.setShowOverlay(false);
      }
    },
    reloadListPreguntas() {
      this.$refs.listPreguntas.searchInList({ search: null });
    }
  },
};
</script>
<style scope>
.question {
  border-radius: 0; /* Asegura que todos los elementos tengan esquinas rectas por defecto */
  border: 1px solid #e2e2e2;
  border-bottom: 0px;
}

.question:first-child {
  border-top-left-radius: 10px; /* Redondea las esquinas superiores */
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0; /* Mantiene las esquinas inferiores rectas */
  border-bottom-right-radius: 0;
  border-bottom: 0px;
}

.question:last-child {
  border-bottom-left-radius: 8px; /* Redondea las esquinas inferiores del último elemento */
  border-bottom-right-radius: 8px;
  border-bottom: 1px solid #e2e2e2;
  margin-bottom: 2rem;
}

.tabs {
  display: flex;
  border-bottom: 1px solid #ccc;
}

.tab-button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: none;
  border: none;
  outline: none;
  margin-right: 0px;
  transition: background-color 0.3s;

  &:first-child {
    border-top-left-radius: 10px;
  }
  &:last-child {
    border-top-right-radius: 10px;
  }
}

.tab-button:hover {
  background-color: #d3d3d3;
}

.tab-button.focus {
  outline: none;
}

.tab-button.active {
  outline: none;
  background-color: #008000;
  border-bottom: 2px solid #000;
  font-weight: bold;
}
</style>
