<template>
  <div class="flex items-center">
    <popup v-if="showPopup" :popup="popupData" @close="handleOk" />
    <base-table
      :title="$t('forms.encuesta.pluralTitle')"
      :records="records"
      @update:records="(val) => (records = val)"
      :table-columns="tableColumns"
      @update:table-columns="(val) => (tableColumns = val)"
      @add-row="addRow"
      @edit-row="editRow"
      @view-row="viewRow"
      @delete-row="deleteRow"
      @pageChanged="onChangePage"
      @search="searchInList"
      @reload-page="searchInList({ search: null })"
      :lang-dict="langDict"
      :popup-data="popupData"
      :show-popup="showPopup"
    >
      <template #searcher-suffix>
        <div class="relative inline-block group">
          <button
            class="download-answers bg-emerald-400 text-white rounded p-2"
            @click="descargarRespuestas(null)"
          >
            <!-- Ícono de descarga (SVG). Puedes sustituirlo por FontAwesome u otro si prefieres -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 inline-block align-middle"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3 16.5V18a2.25 2.25 0 002.25 2.25h13.5A2.25 2.25 0 0021 18v-1.5m-4.5-3l-3 3m0 0l-3-3m3 3V4.5"
              />
            </svg>

            <!-- Tooltip (mostrado solo al hover) -->
            <span
              class="hidden group-hover:block absolute whitespace-nowrap bg-emerald-300 text-white text-xs px-2 py-1 rounded z-10 right-0 -top-6"
              :style="{
                top: 'calc(100% + 0.5rem)',
                left: '50%',
                transform: 'translateX(-50%)',
                width: 'fit-content',
              }"
            >
              Descargar todas las respuestas
            </span>
          </button>
        </div>
      </template>
      <template #dropdown-extra-button="{ row }">
        <a
          href="javascript:void(0);"
          class="dropdown-extra-link text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          @click.prevent.stop="descargarRespuestas(row.id)">
          Descargar respuestas
        </a>
      </template>
    </base-table>
  </div>
</template>
<script>
import BaseTable from "@/components/Cards/BaseTable";
import Popup from "@/components/Cards/Popup.vue";
import { mapActions, mapGetters } from "vuex";
import { baseMixin } from "@/mixins/base";

export default {
  components: {
    BaseTable,
    Popup,
  },
  data() {
    return {
      records: [],
      tableColumns: [],
      langDict: "forms.encuesta.tableColumns",
    };
  },
  async created() {
    this.setShowOverlay(true);
    await this.searchInList({ search: null });
    this.setShowOverlay(false);
  },
  computed: {
    ...mapGetters(["getPageSize", "getPage"]),
  },
  mixins: [baseMixin],
  methods: {
    ...mapActions([
      "getEncuestaList",
      "setEncuestaList",
      "setTableColumns",
      "setPage",
      "setCount",
      "setEncuestaList",
      "deactivateEncuesta",
      "setShowOverlay",
      "getReporteEncuestasCompletasCSV",
    ]),
    async addRow() {
      this.$router.push({ name: "AddEncuesta" });
    },
    async editRow(id) {
      this.$router.push({ name: "EditEncuesta", params: { id } });
    },
    async viewRow(id) {
      this.$router.push({ name: "ViewEncuesta", params: { id } });
    },
    async deleteRow(id) {
      try {
        const res = await this.deactivateEncuesta({ id });

        if (res.status >= 200 && res.status < 300) {
          this.popupData = {
            type: "success",
            message: "La operación se completó con éxito",
          };
        }
        this.showPopup = true;
      } catch (err) {
        this.popupData = {
          type: "error",
          message: err,
        };
        this.showPopup = true;
      } finally {
        this.search = null;
        this.searchInList({ search: this.search });
        this.setShowOverlay(false);
      }
    },
    async searchInList({ search }) {
      const res = await this.getEncuestaList({
        search: search,
        page: this.getPage,
        pageSize: this.getPageSize,
      });

      if (res.status === 200) {
        const { results, tableColumns, count } = await res.json();

        this.records = results;
        this.tableColumns = tableColumns;
        this.setCount(count);
        this.setEncuestaList(results);
        this.setTableColumns(tableColumns);
      }
    },
    async onChangePage(page) {
      this.setPage(page);
      await this.searchInList({ search: null });
    },
    async descargarRespuestas(encuestaId) {
      this.setShowOverlay(true);
      const res = await this.getReporteEncuestasCompletasCSV({ encuestaId });
      const blob = await res.blob();

      // Crea URL para poder descargar el blob
      const downloadUrl = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = encuestaId
        ? `respuestas_encuesta_${encuestaId}.csv`
        : "todas_respuestas_encuestas.csv";

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      // Libera la URL del blob
      URL.revokeObjectURL(downloadUrl);
      this.setShowOverlay(false);
    },
  },
};
</script>
<style scoped>
.hidden {
  display: none;
}
.group:hover .group-hover\:block {
  display: block;
}
::v-deep .dropdown-extra-link:hover {
  background-color: #f1f5f9;
}
.download-answers:hover {
  opacity: 0.95;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transform: scale(1.05)
}
</style>
