<template>
  <div>
    <multiselect
      v-if="listaPreguntas.length > 0"
      v-model="pregunta"
      :placeholder="$t('forms.seccion.modalPreguntas.preguntaPadre')"
      label="descripcion"
      key="id"
      :disabled="isOnlyView"
      :internal-search="false"
      :options-limit="300"
      :options="listaPreguntas"
      :nullable="true"
      @close="onTouch"
      @search-change="findPreguntas"
      @select="handleSelected"
    >
    </multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { baseMixin } from "@/mixins/base";
import { mapActions } from "vuex";

export default {
  components: {
    Multiselect,
  },
  mixins: [baseMixin],
  data() {
    return {
      pregunta: this.modelValue,
      isTouched: false,
      internalValue: this.modelValue,
      listaPreguntas: []
    };
  },
  props: {
    modelValue: {
      type: [String, Number, Object, null],
      required: true,
      default: null,
    },
    seccionId: {
      type: Number,
      default: null,
    },
    isOnlyView: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler() {
        this.internalValue = this.pregunta;
      },
    },
    internalValue(val) {
      this.$emit("update:modelValue", val);
    },
    seccionId: {
      deep: true,
      immediate: true,
      async handler(newVal) {
        if (newVal) {
          this.findPreguntas();
        }
      }
    }
  },
  methods: {
    ...mapActions(["getPreguntaList", "setShowOverlay"]),
    onTouch() {
      this.isTouched = true;
    },
    handleSelected(value) {
      this.$emit("update:modelValue", value);
    },
    async findPreguntas(search) {
      this.setShowOverlay(true);

      const res = await this.getPreguntaList({
        search: search,
        page: this.getPage,
        pageSize: this.getPageSize,
        seccion: this.seccionId ?? null,
      });

      if (res.status === 200) {
        const { results } = await res.json();

        this.listaPreguntas = results;
      }
      this.setShowOverlay(false);
    }
  },
};
</script>
<style scoped>
.font-weight-bold {
  font-weight: bold;
}
.text-red-500 {
  color: #ef4444; /* Mantener el color rojo */
}
.disabled {
  background-color: rgb(241 245 249) !important;
}
</style>
