import SeccionService from '@/api/secciones/secciones'


const SeccionesStore = {
  state: {
    seccionList: [],
    form: {},
  },
  mutations: {
    SET_SECCION: (state, payload) => (state.form = payload),
    SET_SECCION_LIST: (state, list) => (state.seccionList = list),
  },
  actions: {
    async getSeccionList({ dispatch }, { search, page, pageSize, seccion }) {
      const res = await SeccionService.list(search, page, pageSize, seccion);

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    },
    setSeccion({ commit }, payload) {
      commit("SET_SECCION", payload);
    },
    async saveSeccion({ state, dispatch }) {
      const res = state.form.id
        ? await SeccionService.edit(state.form)
        : await SeccionService.create(state.form);

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    },
    async getSeccionById({ dispatch }, { id }) {
      const res = await SeccionService.getById({ id });
      let data = null;

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          data = await res.json();
      }

      return data;
    },
    setSeccionList({ commit }, list) { commit("SET_SECCION_LIST", list) },
    async deactivateSeccion({ dispatch}, { id }) {
      const res = await SeccionService.deactivate({ id });

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    },
  },
  getters: {
    getSeccion: (state) => state.form,
    getSeccionListState: (state) => state.seccionList,
  },
};

export default SeccionesStore;
