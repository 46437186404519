<template>
  <div class="relative bg-blueGray-100" :style="{ height: '93vh', minHeight: '93vh', zIndex: 100 }">
    <iframe
      v-if="url !== ''"
      :key="iframeKey"
      title="riar_lastv"
      width="100%"
      height="100%"
      :src="reportUrl"
      frameborder="0"
      allowFullScreen
    ></iframe>
    <div v-else class="no-report">
      No se ha podido encontrar ningú reporte para el usuario.
    </div>
    <!-- Popup de error -->
    <div v-if="showPopup" class="popup">
      {{ popupMessage }}
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "Reportepbi",
  setup () {
    const store = useStore();
    const url = ref("");
    const iframeKey = ref(0)
    const showPopup = ref(false);
    const popupMessage = ref("");
  
    const reportUrl = computed(() => {
      return `${url.value}&ts=${new Date().getTime()}`;
    });
  
    function refreshIframe() {
      iframeKey.value ++;
    }
  
    onMounted(async() => {
      const res = await store.dispatch("getLinkReporte")

      if (res.status >= 200 && res.status <= 300) {
        const data = await res.json()

        if (data?.link) {
          url.value = data?.link;
        } else {
          showPopup.value = true;
          popupMessage.value = "No se ha podido encontrar el reporte";
        }
      } else {
        showPopup.value = true;
        popupMessage.value = "No se ha podido encontrar el reporte";
      }

      refreshIframe();
    });
  
    return {
      reportUrl,
      iframeKey,
      refreshIframe
    };
  }
};
</script>

<style scoped>
/* Puedes agregar estilos específicos si es necesario */
</style>