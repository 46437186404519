<template>
  <div>
    <overlay/>
    <sidebar />
    <div class="relative md:ml-64 bg-blueGray-100" style="height:100vh">
      <base-header />
      <div class="px-4 md:px-10 mx-auto w-full -m-24 h-full"
        :style="{ minHeight: 'auto', height: 'auto' }"
      >
        <img 
        src="@/assets/img/riart_background.jpg" 
        alt="Imagen de fondo"
        class="absolute top-0 left-0 w-full h-full object-cover"
        />
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import BaseHeader from "@/components/Headers/BaseHeader.vue";
import Overlay from '@/components/Cards/Overlay.vue';

export default {
  name: "base-layout",
  components: {
    Sidebar,
    BaseHeader,
    Overlay
  },
};
</script>
