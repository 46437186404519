<template>
  <div v-if="show" class="modal-overlay">
    <div class="modal-container">
      <h3>Actualizar Orden</h3>
      <input
        type="number"
        v-model.number="order"
        min="1"
        placeholder="Ingrese un número entero positivo"
      />
      <div class="modal-actions">
        <button @click="updateOrder">Actualizar</button>
        <button @click="$emit('close')">Cancelar</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    currentOrder: {
      type: Number,
    }
  },
  data() {
    return {
      order: this.currentOrder
    };
  },
  methods: {
    updateOrder() {
      if (this.order > 0) {
        this.$emit('update-order', this.order);
        this.$emit('close');
      } else {
        alert('Por favor, ingrese un número entero positivo.');
      }
    }
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 300px;
  text-align: center;
}

.modal-actions {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
</style>