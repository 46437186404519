<template>
  <div class="flex relative max-w-xs mr-2">
    <input 
      v-model="search"
      @keyup.enter="emitSearch"
      placeholder="Buscar"
      class="border-2 px-3 py-2 pl-10 rounded w-full"
      />
    <i class="fas fa-search absolute px-4 py-4 text-gray-500"></i>
  </div>
</template>
<script>
export default {
  data() {
    return {
      search: null
    }
  },
  methods: {
    emitSearch() {
      this.$emit('search', this.search)
    }
  }
}
</script>