import LinkReporteService from "@/api/reportes/link_reporte";

const LinkReporteStore = {
  state: {
    linkReporteList: [],
    form: {},
  },
  mutations: {
    SET_LINK_REPORTE: (state, payload) => (state.form = payload),
    SET_LINK_REPORTE_LIST: (state, list) => (state.linkReporteList = list),
  },
  actions: {
    async getLinkReporte({ dispatch }) {
      const res = await LinkReporteService.getLinkReporte();

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    },
    async getLinkReportePorNegocioList({ dispatch },{ search, page, pageSize }) {
      const res = await LinkReporteService.list(search, page, pageSize);

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    },
    async getLinkReportePorNegocioById({ dispatch }, { id }) {
      const res = await LinkReporteService.getById({ id });
      let data = null;

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          data = await res.json();
      }

      return data;
    },
    async setLinkReportePorNegocioList({ commit }, payload) {
      commit("SET_LINK_REPORTE_LIST", payload);
    },
    async saveLinkReportePorNegocio({ dispatch }, payload) {
      console.log("payload: ", payload)
      const res = payload.id
        ? await LinkReporteService.edit(payload)
        : await LinkReporteService.create(payload);

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    },
    async deactivateLinkReportePorNegocio({ dispatch }, id) {
      const res = await LinkReporteService.deactivate({ id });

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    },
  },
  getters: {
    linkReporteList: (state) => state.linkReporteList,
    linkReporteForm: (state) => state.form,
  },
};

export default LinkReporteStore;
