<template>
  <crud-base
    :title="
      encuesta
        ? `Completar Encuesta: ${encuesta.fecha_inicio} / ${encuesta.fecha_fin}`
        : 'Cargando Encuesta...'
    "
    :popup-data="popupData"
    :show-popup="showPopup"
    @cancel="returnToList"
    @add-register="save"
    @ok="popupData.type === 'error' ? (showPopup = false) : returnToList()"
  >
    <template v-slot:form>
      <div v-if="encuesta && encuesta.secciones">
        <Wizard class="px-2" :totalSteps="encuesta.secciones.length">
          <template v-slot="{ currentStep }">
            <WizardStep
              v-for="(seccion, index) in encuesta.secciones"
              :key="index"
              :stepNumber="index + 1"
              :currentStep="currentStep"
              :seccion="seccion"
              :respuestas="respuestasUsuario[index]"
              :is-only-view="isOnlyView"
            />
          </template>
        </Wizard>
      </div>
    </template>
  </crud-base>
</template>

<script>
import CrudBase from "@/components/Cards/CrudBase.vue";
import Wizard from "@/components/Wizard/Wizard.vue";
import WizardStep from "@/components/Wizard/WizardStep.vue";
import { baseMixin } from "@/mixins/base";
import { mapActions } from "vuex";

export default {
  components: {
    CrudBase,
    Wizard,
    WizardStep,
  },
  mixins: [baseMixin],
  data() {
    return {
      encuesta: {},
      respuestas: {},
      preguntas: {},
      respuestasUsuario: [],
      module: "CompletarEncuesta",
      capitalizeModule: "CompletarEncuesta",
      listName: "ListaEncuestasCompletas",
      showTooltip: false,
      showPopup: false,
      popupData: {},
    };
  },
  async created() {
    this.isLoading = !this.isLoading;
    this.setShowOverlay(this.isLoading);

    const res = await this.getEncuestaUsuarioEmpresa();
    this.encuesta = res[0];

    if (this.$route.params.id) {
      await this.obtenerRespuestaEncuesta();
    }

    this.isLoading = !this.isLoading;
    this.setShowOverlay(this.isLoading);
  },
  methods: {
    ...mapActions([
      "setShowOverlay",
      "getEncuestaUsuarioEmpresa",
      "guardarEncuestaUsuarioEmpresa",
      "getEncuestaCompletaById",
      "getRespuestasUsuarioEmpresa",
      "setPopup",
    ]),
    actualizarRespuestas({ preguntaId, respuesta }) {
      this.respuestas = {
        ...this.respuestas,
        [preguntaId]: respuesta,
      };
      this.actualizarPreguntasHijas(preguntaId, respuesta);
    },
    actualizarPreguntasHijas(preguntaId, respuesta) {
      this.encuesta.secciones.forEach((seccion) => {
        seccion.preguntas.forEach((pregunta) => {
          if (pregunta.padre === preguntaId) {
            if (respuesta.descripcion.toUpperCase() === "NO APLICA") {
              pregunta.oculta = true;
            } else {
              pregunta.oculta = false;
            }
          }
        });
      });
      this.$forceUpdate();
    },
    actualizarRespuestasInput({ preguntaId, descripcion }) {
      this.preguntas = {
        ...this.preguntas,
        [preguntaId]: descripcion,
      };
    },
    async obtenerRespuestaEncuesta() {
      if (!this.encuesta || !this.encuesta.secciones) {
        console.error("Las secciones de la encuesta no están disponibles.");
        return;
      }

      const res = await this.getEncuestaCompletaById({
        id: this.$route.params.id,
      });

      const { preguntas, respuestas } = res;

      if (
        (preguntas && preguntas.length > 0) ||
        (respuestas && respuestas.length > 0)
      ) {
        respuestas.forEach((resp) => {
          this.respuestasUsuario.forEach((sec) => {
            sec.preguntas.filter((p) => {
              if (p.id === resp.pregunta_id) {
                if (p.respuesta && p.respuesta.length > 0) {
                  p.respuesta.push(resp.respuesta);
                } else {
                  p.respuesta = [this.buscarRespuesta(resp)]; //[resp.respuesta];
                }
                p.tipo_respuesta = resp.tipo_respuesta;
              }
            });
          });
        });

        preguntas.forEach((resp) => {
          this.respuestasUsuario.forEach((sec) => {
            sec.preguntas.filter((p) => {
              if (p.id === resp.pregunta) {
                p.respuesta = resp.descripcion;
                p.tipo_respuesta = resp.tipo_respuesta;
              }
            });
          });
        });
      }
      this.$forceUpdate();
    },
    buscarRespuesta(respSeleccionada) {
      let retornarRespuesta = respSeleccionada.respuesta;

      this.encuesta.secciones.forEach((sec) => {
        let pregunta = sec.preguntas.filter(
          (pr) => pr.id === respSeleccionada.pregunta_id
        );

        if (pregunta && pregunta.length > 0) {
          pregunta = pregunta[0];

          if (!pregunta.multiple) {
            const { respuestas } = pregunta;

            retornarRespuesta = respuestas.filter(
              (resp) => resp.id === respSeleccionada.respuesta
            )[0];
          }
        }
      });

      return retornarRespuesta;
    },
    async save() {
      const payload = {
        encuesta: this.encuesta.id,
        respuestas: [],
        preguntas: [],
      };

      this.respuestasUsuario.forEach((seccion) => {
        seccion.preguntas.forEach((pregunta) => {
          // Solo procesamos si hay una respuesta (distinta de null y que tenga información válida)
          if (pregunta.respuesta !== null && pregunta.respuesta !== undefined) {
            // Si la respuesta es un objeto y no corresponde al tipo 3 (texto libre, por ejemplo)
            if (
              typeof pregunta.respuesta === "object" &&
              pregunta.tipo_respuesta !== 3
            ) {
              if (
                Array.isArray(pregunta.respuesta) &&
                pregunta.respuesta.length > 0
              ) {
                pregunta.respuesta.forEach((res) => {
                  // Si la respuesta es un objeto, se filtra si está vacío (sin propiedades) o no tiene 'id'
                  if (res && typeof res === "object") {
                    if (Object.keys(res).length > 0 && res.id != null) {
                      payload.respuestas.push({ respuesta: res.id });
                    }
                  } else if (res) {
                    payload.respuestas.push({ respuesta: res });
                  }
                });
              } else {
                // Si no es array, verificamos que el objeto tenga contenido y la propiedad id
                if (
                  typeof pregunta.respuesta === "object" &&
                  Object.keys(pregunta.respuesta).length > 0 &&
                  pregunta.respuesta.id != null
                ) {
                  payload.respuestas.push({ respuesta: pregunta.respuesta.id });
                }
              }
            } else if (pregunta.tipo_respuesta === 3) {
              if (pregunta.respuesta.length > 0) {
                payload.preguntas.push({
                  pregunta: pregunta.id,
                  descripcion: pregunta.respuesta,
                });
              }
            }
          }
        });
      });

      this.setShowOverlay(true);
      try {
        const res = await this.guardarEncuestaUsuarioEmpresa({
          encuesta: payload,
          idEncuesta: this.$route.params.id,
        });
        const data = await res.json();
        const isOk = res.status >= 200 && res.status <= 300;

        this.setShowOverlay(false);

        if (isOk) {
          this.popupData = {
            show: true,
            type: "success",
            title: this.$t("popup.success.title"),
            message: this.$t("popup.success.message"),
          };
        } else {
          this.popupData = {
            show: true,
            type: "error",
            title: this.$t("popup.error.title"),
            message: data,
          };
        }
        this.showPopup = true;
      } catch (error) {
        this.setShowOverlay(false);
        this.popupData = {
          show: true,
          type: "error",
          title: this.$t("popup.error.title"),
          message: this.$t("popup.error.message"),
        };
        this.showPopup = true;
      }
    },
    initObjetoRespuestasUsuario() {
      this.encuesta.secciones.map((sec) => {
        this.respuestasUsuario.push({
          id: sec.id,
          preguntas: sec.preguntas.map((pr) => ({
            id: pr.id,
            respuesta: null,
          })),
        });
      });
    },
  },
  watch: {
    encuesta: {
      deep: true,
      handler() {
        if (this.encuesta && this.encuesta.secciones) {
          this.initObjetoRespuestasUsuario();
        }
      },
    },
  },
};
</script>
