<template>
  <div class="items-center"
    :style="{'width': '100%', 'height': 'auto', 'z-index': '100'}"
  >
    <confirm-popup
      v-if="showConfirmPopup"
      :popup="confirmPopupData"
      @confirm-action="deleteRow"
      @cancel-action="cancelAction"
    />
  <div
    class="relative flex-col min-w-0 break-words w-full rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center py-4">
        <div
          v-if="showTitle" 
          class="relative w-full max-w-full flex-grow flex-1">
          <h3
            class="font-semibold text-xl"
            :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
          >
            {{ title }}
          </h3>
        </div>
      </div>
      <div class="flex items-center py-4">
        <div class="w-1/2">
          <searcher @search="handleSearch"/>
        </div>
        <div class="flex-grow flex items-center justify-start">
          <slot name="searcher-suffix"></slot>
        </div>
        <div class="flex justify-end">
          <button
            v-if="showAddBtn"
            class="add-button border-none px-4 py-2 rounded bg-secondary text-white font-semibold"
            @click.prevent.stop="$emit('add-row')"
          >
            {{ $t('btnLabels.add') }}
          </button>
        </div>
      </div>
    </div>
    <div 
      class="w-full overflow-x-auto flex flex-col"
      :style="{minHeight: '65vh', justifyContent: 'space-between'}"
    >
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="px-6 align-middle border-none py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
              v-for="col in tableColumns" :key="col.label"
            >
              {{ col.label }}
            </th>
            <th
              v-if="showOrderBtn"
              class="px-6 align-middle border-none py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-right"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              {{ 'Ordenar' }}
            </th>
            <th
              style="position: sticky; right: 0; z-index: 1;"
              class="px-6 align-middle border-none py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-right"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              {{ $t('baseList.table.columnName.actions')}}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <th
              class="px-6 align-middle py-2 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              v-for="col in tableColumns" :key="col.value"
            >
              {{ row[col.value] }}
            </th>
            <th
              v-if="showOrderBtn" 
              class="px-6 align-middle py-2 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-right">
              <button @click.prevent="$emit('ordenar', row)"><i class="fas fa-sort p-2"></i></button>
            </th>
            <th
              style="position: sticky; right: 0; z-index: 2; background: white;"
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right"
            >
              <table-dropdown
                @edit-row="editRow(row)"
                @view-row="viewRow(row)"
                @delete-row="confirmDeleteRow(row)"
                :show-edit-btn="showEditBtn"
                :show-view-btn="showViewBtn"
                :show-delete-btn="showDeleteBtn"
              >
                <template #dropdown-extra-button>
                  <slot name="dropdown-extra-button" :row="row"></slot>
                </template>
              </table-dropdown>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex w-full py-2 overflow-x-hidden justify-center">
      <pagination-table
        :current-page="getPage"
        :total-items="getCount"
        :perPage="getPageSize"
        @pageChanged="onPageChange"
      />
    </div>
  </div>
  </div>
</template>
<script>
import TableDropdown from "@/components/Dropdowns/TableDropdown.vue";
import PaginationTable from "@/components/Cards/PaginationTable.vue";
import Searcher from '@/components/Inputs/Searcher.vue';
import ConfirmPopup from "@/components/Cards/ConfirmPopup.vue"
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      confirmPopupData: {},
      showConfirmPopup: false,
      deleteObject: null
    };
  },
  components: {
    TableDropdown,
    PaginationTable,
    Searcher,
    ConfirmPopup
  },
  computed: {
    ...mapGetters(['getCount', 'getPage', 'getPageSize']),
    totalPages () {
      return Math.ceil(this.getCount/this.getPageSize);
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.records.slice(start, end);
    }
  },
  methods: {
    ...mapActions(['setShowOverlay']),
    async editRow (row) {
      this.$emit('edit-row', await row.id)
    },
    async viewRow (row) {
      this.$emit('view-row', await row.id)
    },
    confirmDeleteRow (row) {
      this.deleteObject = row
      this.setShowOverlay(true)
      this.confirmPopupData = {
        type: 'warning',
        message: 'Está seguro de continuar con esta acción?'
      }
      this.showConfirmPopup = true
    },
    async deleteRow () {
      this.showConfirmPopup = false
      this.$emit( 'delete-row', this.deleteObject.id)
    },
    cancelAction() {
      this.deleteObject = null
      this.setShowOverlay(false)
      this.showConfirmPopup = false
    },
    async onPageChange(page) {
      this.currentPage = page;
      this.$emit('pageChanged', this.currentPage)
    },
    handleSearch(query) {
      this.$emit('search', {search: query})
    }
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
    title: {
        default: 'Lista',
        required: true,
    },
    records: {
      type: Array,
      required: true,
      default: () => []
    },
    tableColumns: {
      type: Array,
      required: true,
      default: () => []
    },
    langDict: {
      type: String,
      required: true
    },
    showAddBtn: {
      type: Boolean,
      default:true
    },
    showEditBtn: {
      type: Boolean,
      default:true
    },
    showViewBtn: {
      type: Boolean,
      default:true
    },
    showDeleteBtn: {
      type: Boolean,
      default:true
    },
    showTitle: {
      type: Boolean,
      default:true
    },
    height: {
      type: String,
      default: '100vh'
    },
    showOrderBtn: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'add-row',
    'edit-row',
    'view-row',
    'delete-row',
    'go-to-first-page',
    'go-to-previous-page',
    'go-to-next-page',
    'go-to-last-page',
    'input',
    'pageChanged',
    'ok',
    'search'
  ]
};
</script>
<style>
.smart-pagination {
  display: flex
}
.add-button {
  transition: all 0.2s ease;
}

.add-button:hover {
  opacity: 0.95;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transform: scale(1.05)
}
</style>
