<template>
  <div class="flex items-center"
  >
    <popup
      v-if="showPopup"
      :popup="popupData"
      @close="handleOk"
    />
  <base-table
    title="Lista de links de reportes por negocio"
    :show-order-btn="showOrderBtn"
    :records="records"
    @update:records="(val) => (records = val)"
    :table-columns="tableColumns"
    @update:table-columns="(val) => (tableColumns = val)"
    @add-row="addRow"
    @edit-row="editRow"
    @view-row="viewRow"
    @delete-row="deleteRow"
    @pageChanged="onChangePage"
    @reload-page="searchInList({ search: null })"
    @search="searchInList"
    :lang-dict="langDict"
  />
  </div>
</template>
<script>
import BaseTable from "@/components/Cards/BaseTable";
import Popup from "@/components/Cards/Popup.vue";
import { mapActions, mapGetters } from "vuex";
import { baseMixin } from "@/mixins/base";

export default {
  components: {
    BaseTable,
    Popup
  },
  data() {
    return {
      records: [],
      tableColumns: [],
      langDict: "forms.negocio.tableColumns",
    };
  },
  computed: {
    ...mapGetters(["getPageSize", "getPage"]),
  },
  mixins: [baseMixin],
  methods: {
    ...mapActions([
      "getLinkReportePorNegocioList",
      "setLinkReportePorNegocioList",
      "setTableColumns",
      "setPage",
      "setCount",
      "setShowOverlay",
      "deactivateLinkReportePorNegocio",
    ]),
    async addRow() {
      this.$router.push({ name: "AddLinkReportePorNegocio" });
    },
    async editRow(id) {
      this.$router.push({ name: "EditLinkReportePorNegocio", params: { id } });
    },
    async viewRow(id) {
      this.$router.push({ name: "ViewLinkReportePorNegocio", params: { id } });
    },
    async deleteRow(id) {
      try {
        const res = await this.deactivateLinkReportePorNegocio(id);

        if (res.status >= 200 && res.status < 300) {
          this.popupData = {
            type: "success",
            message: "La operación se completó con éxito",
          };
        }
        this.showPopup = true;
      } catch (err) {
        this.popupData = {
          type: "error",
          message: err,
        };
        this.showPopup = true;
      } finally {
        this.search = null;
        this.searchInList({ search: this.search });
        this.setShowOverlay(false);
      }
    },
    async ordenar(row) {
      this.$emit("ordenar", row);
    },
    async searchInList({ search }) {
      this.setShowOverlay(true);

      const res = await this.getLinkReportePorNegocioList({
        search: search,
        page: this.getPage,
        pageSize: this.getPageSize,
        seccion: this.seccionId ?? null,
      });

      if (res.status === 200) {
        const { results, tableColumns, count } = await res.json();

        this.records = results;
        this.tableColumns = tableColumns;
        this.setLinkReportePorNegocioList(results);
        this.setCount(count);
        this.setTableColumns(tableColumns);
      }
      this.setShowOverlay(false);
    },
    async onChangePage(page) {
      this.setPage(page);
      await this.searchInList({ search: null });
    },
  },
  watch: {
    seccionId: {
      handler: function () {
        this.searchInList({ search: null });
      },
      immediate: true,
    },
  }
};
</script>
