<template>
  <dialog
    v-if="showModalPregunta"
    class="bg-white absolute flex flex-col w-full rounded-lg shadow-lg"
    :style="{ top: 0, left: 0, height: '95vh', 'z-index': 100 }"
  >
    <popup
      v-if="showPopup"
      :popup="popupData"
      @close="handleOk"
    />
    <div class="flex w-full px-2 justify-between">
      <div class="rounded-t mb-0 px-2 py-6">
        <div class="text-center flex justify-between">
          <h6 class="text-blueGray-700 text-xl font-bold">
            {{ `Detalles de la Pregunta` }}
          </h6>
        </div>
      </div>
      <button class="px-2" @click.prevent="$emit('close-modal')">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <hr :style="{ borderTopWidth: '2px' }" />
    <div
      v-if="modalForm"
      class="flex w-full flex-col px-2 py-2 bg-white h-full overflow-y-scroll"
    >
      <div class="flex flex-wrap">
        <div class="w-full lg:w-6/12 px-2">
          <custom-input
            :label="$t('forms.seccion.modalPreguntas.descripcion')"
            :disabled="isOnlyViewModal"
            type="text"
            v-model="modalForm.descripcion"
          />
        </div>
        <div class="w-full lg:w-6/12 px-2">
          <label
            class="w-full block uppercase text-blueGray-600 text-xs font-bold mb-2"
          >
            {{ $t("forms.seccion.modalPreguntas.tipoRespuesta") }}
          </label>
          <multiselect
            v-model="modalForm.tipo_respuesta"
            label="text"
            :disabled="isOnlyViewModal"
            :allow-empty="false"
            :clear-on-select="false"
            :options="getTiposRespuesta"
          />
          <!-- <label
            v-show="isInvalidTipoRespuesta"
            class="type__label form__label"
          >
            Error de tipo de respuesta
          </label> -->
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full lg:w-6/12 px-2">
          <custom-input
            :label="$t('forms.seccion.eje')"
            :placeholder="$t('forms.seccion.fieldsPlaceholder.eje')"
            :disabled="isOnlyViewModal"
            type="text"
            v-model="modalForm.eje"
          />
        </div>
        <div class="w-full lg:w-6/12 px-2">
          <custom-input
            :label="$t('forms.seccion.campoPertenece')"
            :placeholder="$t('forms.seccion.fieldsPlaceholder.campoPertenece')"
            :disabled="isOnlyViewModal"
            type="text"
            v-model="modalForm.campo_pertenece"
          />
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full lg:w-6/12 px-2">
          <custom-input
            :label="$t('forms.seccion.tituloInforme')"
            :placeholder="$t('forms.seccion.fieldsPlaceholder.tituloInforme')"
            :disabled="isOnlyViewModal"
            type="text"
            v-model="modalForm.titulo_informe"
          />
        </div>
        <div class="flex w-full lg:w-6/12 px-2 justify-between">
          <div class="flex lg:w-1 px-2 py-2 items-center">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mr-2"
              >{{ $t("forms.seccion.modalPreguntas.beneficio") }}</label
            >
            <input
              id="checkBeneficio"
              type="checkbox"
              :disabled="isOnlyViewModal"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
              v-model="modalForm.beneficio"
            />
          </div>
          <div class="flex lg:w-1 px-2 py-2 items-center">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mr-2"
              >{{ $t("forms.seccion.modalPreguntas.cerrada") }}</label
            >
            <input
              id="checkEsCerrada"
              type="checkbox"
              :disabled="isOnlyViewModal"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
              v-model="modalForm.cerrada"
            />
          </div>
          <div class="flex lg:w-1 px-2 py-2 items-center">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mr-2"
              >{{ $t("forms.seccion.modalPreguntas.multiple") }}</label
            >
            <input
              id="checkMultiple"
              type="checkbox"
              :disabled="isOnlyViewModal"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
              v-model="modalForm.multiple"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full lg:w-6/12 px-2">
          <label
            class="w-full block text-blueGray-600 text-xs font-bold mb-2"
          >
            {{ $t("forms.seccion.modalPreguntas.preguntaPadre") }}
          </label>
          <multiselect-preguntas
            v-model="modalForm.padre"
            :seccion-id="seccion.id"
            :is-only-view="isOnlyViewModal"
          />
        </div>
      </div>
      <div 
        v-if="!showRespuesta"
        class="flex w-full h-full flex-col mt-2">
        <div class="flex w-full px-2 mb-2">
          <span
            class="block uppercase text-blueGray-600 text-xs font-bold py-3 mr-2"
          >
            {{ $t("forms.seccion.modalPreguntas.respuestas") }}
          </span>
          <button
            v-if="!isOnlyViewModal"
            class="text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none"
            type="button"
            :style="{ backgroundColor: 'cadetblue' }"
            @click.prevent="agregarRespuesta"
          >
            <i class="fa fa-plus"></i>
          </button>
        </div>
        <div
          class="flex flex-col border rounded-lg m-2 h-full overflow-y-scroll"
        >
          <respuesta
            v-for="(item, idx) in modalForm.respuestas"
            :key="idx"
            :respuesta="item"
            :index="idx"
            :is-only-view="!isOnlyViewModal"
            :is-last-item="isLastItem(idx)"
            @remove-respuesta="handleRemoveRespuesta"
            @update-respuesta="updateRespuesta"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-auto justify-end px-4 lg:px- py-4">
      <button
        v-if="!isOnlyViewModal"
        class="ml-1 text-white bg-secondary font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        type="button"
        @click.prevent.stop="save"
      >
        {{ isEdit ? $t("btnLabels.update") : $t("btnLabels.save") }}
      </button>
    </div>
  </dialog>
</template>
<script>
import CustomInput from "@/components/Inputs/CustomInput";
import Respuesta from "@/components/Encuestas/Respuesta";
import MultiselectPreguntas from "@/components/Encuestas/MultiselectPreguntas";
import Multiselect from "vue-multiselect";
import { mapActions, mapGetters } from "vuex";
import { baseMixin } from "@/mixins/base";
import Popup from "@/components/Cards/Popup.vue";

export default {
  components: {
    CustomInput,
    Multiselect,
    Respuesta,
    MultiselectPreguntas,
    Popup
  },
  props: {
    showModalPregunta: {
      type: Boolean,
      required: true,
      default: false,
    },
    isEdit: {
      default: false,
      type: Boolean,
    },
    isOnlyViewModal: {
      default: false,
      type: Boolean,
    },
    preguntaActual: {
      type: Object,
      default: () => ({})
    },
    preguntaActualId: {
      type: Number,
      default: null
    },
    seccion: {
      type: Object,
    }
  },
  mixins: [baseMixin],
  data() {
    return {
      tipoRespuestaList: [],
      modalForm: this.initializeModalForm(),
    };
  },
  async created() {
    await this.getTipoRespuestaList();
  },
  computed: {
    ...mapGetters(["getTiposRespuesta"]),
    showRespuesta() {
      return this.modalForm?.tipo_respuesta?.id === 3
    }
  },
  methods: {
    ...mapActions([
      "setForm", 
      "setNewRespuesta", 
      "getTipoRespuestaList",
      "savePregunta",
      "setPregunta",
      "getPreguntaById",
      "setShowOverlay",
      "getPreguntaList"
    ]),
    initializeModalForm() {
      return {
        descripcion: null,
        tipo_respuesta: null,
        eje: null,
        campo_pertenece: null,
        titulo_informe: null,
        beneficio: false,
        multiple: false,
        cerrada: false,
        padre: null,
        respuestas: [],
      };
    },
    commitQuestion() {
      this.$emit(this.isEdit ? "update-question" : "commit-question", this.modalForm);
      this.$emit("close-modal");
    },
    isInvalidTipoRespuesta() {
      return false;
    },
    agregarRespuesta() {
      this.modalForm.respuestas.push({
        descripcion: '',
      });
    },
    isLastItem(idx) {
      return (
        this.modalForm.respuestas.length - 1 === idx
      );
    },
    handleRemoveRespuesta(idx) {
      this.modalForm.respuestas.splice(idx, 1);
    },
    updateRespuesta({ index, descripcion }) {
      this.modalForm.respuestas[index].descripcion = descripcion;
    },
    async getPregunta() {
      try {
        let padre;
        this.setShowOverlay(true);

        const pregunta = await this.getPreguntaById({id: this.preguntaActualId});

        if (pregunta.padre) {
          padre = await this.getPreguntaById({id: pregunta.padre});
        }

        this.modalForm = {
          ...pregunta,
          tipo_respuesta: this.getTiposRespuesta.find((item) => item.id === pregunta.tipo_respuesta),
          padre: padre ?? null,
        };
        
      } catch (error) {
        const err = error.message || error;
        console.log(err);

        this.setShowOverlay(false);
        this.popupData = {
          show: true,
          type: "error",
          title: this.$t("popup.error.title"),
          message: err || this.$t("popup.error.message")
        };
        this.showPopup = true;
      } finally {
        this.setShowOverlay(false);
      }
    },
    handleOk() {
      this.showPopup = false;
      this.popupData.type === "error" ? null : this.$emit("close-modal");
    },
    async save () {
      try {
        if (this.modalForm.respuestas.length === 0 && this.modalForm.tipo_respuesta.id !== 3) {
          this.popupData = {
            show: true,
            type: "error",
            title: this.$t("popup.error.title"),
            message: "Debe agregar al menos una respuesta",
          }
          this.showPopup = true;
          return;
        }
        this.setShowOverlay(true);
        const pregunta = {
          ...this.modalForm,
          seccion: this.$route.params.id  ?? this.seccion?.id,
          tipo_respuesta: this.modalForm.tipo_respuesta.id,
          padre: this.modalForm.padre?.id,
          respuestas: this.modalForm.respuestas.map((item) => {
            const resp = {
              descripcion: item.descripcion,
            };
            if (item.id) resp.id = item.id;

            return resp;
          }),
        }
        const res = await this.savePregunta(pregunta);

        if (res.ok) {
          this.popupData = {
            show: true,
            type: "success",
            title: this.$t("popup.success.title"),
            message: this.$t("popup.success.message"),
          }
        } else {
          const error = await res.json();
          let err = error?.non_field_errors[0] || error;

          this.popupData = {
            show: true,
            type: "error",
            title: this.$t("popup.error.title"),
            message: err,
          }
        }
        this.showPopup = true;
      } catch (error) {
        const err = error.message || error;
        console.log(err);

        this.setShowOverlay(false);
        this.popupData = {
          show: true,
          type: "error",
          title: this.$t("popup.error.title"),
          message: err || this.$t("popup.error.message")
        };
        this.showPopup = true;
      } finally {
        this.setShowOverlay(false);
      }
    }
  },
  watch: {
    preguntaActualId: {
      deep: true,
      immediate: true,
      async handler(newVal) {
        if (newVal) {
          await this.getPregunta();
        } else {
          this.modalForm = this.initializeModalForm();
        }
      }
    }
  }
}
</script>
