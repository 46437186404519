import { createI18n } from 'vue-i18n';

const messages = {
  en: {
    message: {
      language: {
        language: "Language",
        english: "English",
        spanish: "Spanish",
      },
      login: {
        username: "Username",
        login: "Log in",
        email: "Email",
        emailExample: "example@example.com",
        password: "Password",
        confirmPassword: "Confirm @.lower:message.login.password",
        forgotPassword: "Forgot password",
        createNewAccount: "Create new account",
        rememberMe: "Remember me",
        signIn: "Sign in",
        logout: "Logout",
        passwordRecovery: "Recovery password",
        passwordRecoveryMessage:
          "Enter the e-mail address to which a code will be sent to recover your password",
        send: "Send",
        verificationCode: "Verification code",
        verificationCodeMessage:
          "The reset password link was sent to {email}.  Please verify the inbox.",
        resetPassword: "Reset password",
        continue: "Continue",
        showPassword: "Show password",
        hiddePassword: "Hidde password",
      },
      signUp: {
        signUp: "Sign up",
        name: "Name",
        email: "Email",
        emailExample: "example@example.com",
        password: "Password",
        iAgree: "I agree with the",
        privacyPolicy: "Privacy Policy",
        createAccount: "Create account",
      },
      user: {
        users: "Users",
        editMyProfile: "Edit my profile",
        groups: {
          groups: "Groups",
        },
      },
      errorMessage: {
        required: "This field is required",
      },
    },
    baseList: {
      table: {
        columnName: {
          actions: "Actions",
        },
      },
      pagination: {
        first: "First",
        last: "Last",
        next: "Next",
        previous: "Previous",
        showing: "Showing",
        to: "to",
        of: "of",
        results: "results",
      },
    },
    btnLabels: {
      edit: "Editar",
      delete: "Delete",
      add: "Add",
      view: "View",
      goEnd: "Go to end",
      goFirst: "Go to first",
      cancel: "Cancel",
      ok: "OK",
    },
    home: {
      title: "Home",
    },
    multiselect: {
      placeholder: "Select an option",
      selectLabel: "Press enter to select",
    },
    forms: {
      userGroup: {
        title: "Group",
        addTitle: "Add @:forms.userGroup.title",
        editTitle: "Edit @:forms.userGroup.title",
        viewTitle: "View @:forms.userGroup.title",
        permissionsTitle: "Permissions",
        form: {
          name: "Name",
          deleteAllPermissions: "Delete all",
          addAllPermissions: "Add all",
        },
        fields: {
          id: "ID",
          name: "Name",
        },
        fieldsPlaceholder: {
          name: "Enter an user group name",
        },
        tableColumns: {
          id: "ID",
          name: "Name",
        },
      },
      user: {
        title: "User",
        addTitle: "Add @:forms.user.title",
        editTitle: "Update @:forms.user.title",
        titlePlural: "Users",
        fields: {
          username: "User",
          email: "Email",
          firstName: "Firstname",
          lastName: "Lastname",
          password: "Password",
          confirmPassword: "Verify password",
          empresa: "Company",
          grupo: "Group",
        },
        fieldsPlaceholder: {
          username: "Enter the username",
          email: "Enter the email",
          firstName: "Enter the firstname",
          lastName: "Enter the lastname",
          password: "Enter a password",
          confirmPassword: "Enter the same password",
          empresa: "Select a company",
          group: "Select a group",
        },
        tableColumns: {
          id: "id",
          username: "User",
          email: "Email",
          first_name: "Firstname",
          last_name: "Lastname",
          empresa: "Company",
        },
      },
      negocio: {
        title: "Business",
        titlePlural: "Business",
        addTitle: "Add Business",
        editTitle: "Edit Business",
        fields: {
          descripcion: "Description",
        },
        fieldsPlaceholder: {
          descripcion: "Enter the business name",
        },
        tableColumns: {
          id: "ID",
          descripcion: "Description",
        },
      },
      segmento: {
        title: "Segment",
        titlePlural: "Segments",
        addTitle: "Add Segment",
        editTitle: "Edit Segment",
        fields: {
          descripcion: "Description",
        },
        fieldsPlaceholder: {
          descripcion: "Enter the Segment name",
        },
        tableColumns: {
          id: "ID",
          descripcion: "Description",
        },
      },
      origen: {
        title: "Source",
        pluraltitle: "Sources",
        addTitle: "Add Source",
        editTitle: "Edit Source",
        fields: {
          descripcion: "Description",
        },
        fieldsPlaceholder: {
          descripcion: "Enter the Source name",
        },
        tableColumns: {
          id: "ID",
          descripcion: "Description",
        },
      },
      empresa: {
        title: "Company",
        addTitle: "Add company",
        editTitle: "Update Company",
        viewTitle: "View Company",
        titlePlural: "Companies",
        fields: {
          razonSocial: "Company name",
          rucCi: "RUC o CI",
          direccion: "Address",
          negocio: "Bussiness",
          segmento: "Segment",
          origen: "Source",
          cantSucursales: "Number of branches",
          cantEmpleados: "Number of employees",
        },
        fieldsPlaceholder: {
          razonSocial: "Company Name",
          rucCi: "123456789-0",
          direccion: "Enter an address",
          negocio: "Select a bussiness",
          segmento: "Select a Segment",
          origen: "Select a source",
          cantSucursales: "Enter the number of branches",
          cantEmpleados: "Enter the number of employees",
        },
        tableColumns: {
          id: "ID",
          razon_social: "Company name",
          ruc_ci: "RUC o CI",
          direccion: "Address",
        },
      },
      idiomas: {
        title: "Languages",
        addTitle: "Add Languages",
        editTitle: "Edit Languages",
        viewTitle: "View Languages",
        pluralTitle: "Languages",
        fields: {
          nombre: "Name",
          codigo: "Code",
        },
        fieldsPlaceholder: {
          nombre: "Enter a language name",
          codigo: "Enter a language code",
        },
        tableColumns: {
          id: "ID",
          nombre: "Name",
          codigo: "Code",
        },
      },
      seccion: {
        title: "Section",
        pluralTitle: "Sections",
        descripcion: "Description",
        eje: "Axis",
        campoPertenece: "Field it belongs to",
        tituloInforme: "Title of the report",
        preguntas: "Questions",
        fields: {
          descripcion: "Description",
          idioma: "Language",
          eje: "Enter the axis name",
          campoPertenece: "Enter the field to witch it belongs",
          tituloInforme: "Enter the title in the report",
        },
        fieldsPlaceholder: {
          descripcion: "Enter a section description",
          idioma: "Enter a language",
        },
        tableColumns: {
          id: "ID",
          descripcion: "Description",
          idioma: "Language",
        },
        modalPreguntas: {
          title: "New Question",
          descripcion: "Description",
          tipoRespuesta: "Question type",
          respuesta: "Answer",
          respuestas: "Answers",
          multiselect: "Pregunta a la que está relacionada esta pregunta",
        },
      },
      encuesta: {
        title: "Survey",
        addTitle: "Add Survey",
        editTitle: "Edit Survey",
        pluralTitle: "Surveys",
        completar: "Fill survey",
      },
      periodo: {
        title: "Periodo",
        addTitle: "Agregar Periodo",
        editTitle: "Editar Periodo",
        pluralTitle: "Periodos",
      },
      periodoActivo: {
        title: "Active Period",
        addTitle: "Aadd Active Period",
        editTitle: "Edit Active Period",
        pluralTitle: "Active Periods",
      },
    },
  },
  es: {
    message: {
      language: {
        language: "Idioma",
        english: "Inglés",
        spanish: "Español",
      },
      login: {
        username: "Nombre de usuario",
        login: "Iniciar sesión",
        email: "Correo electrónico",
        emailExample: "ejemplo@ejemplo.com",
        password: "Contraseña",
        confirmPassword: "Confirmar @.lower:message.login.password",
        forgotPassword: "Olvidó su contraseña",
        createNewAccount: "Crear nueva cuenta",
        rememberMe: "Recordarme",
        signIn: "Ingresar",
        logout: "Cerrar sessión",
        passwordRecovery: "Recuperar contraseña",
        passwordRecoveryMessage:
          "Ingrese el correo al que se enviará un código para recuperar su contraseña",
        send: "Enviar",
        verificationCode: "Código de verificación",
        verificationCodeMessage:
          "El link para restablecer su contraseña fue enviado a {email}. Por favor revise su bandeja de entrada.",
        resetPassword: "Restablecer contraseña",
        continue: "Continuar",
        showPassword: "Mostrar contraseña",
        hiddePassword: "Ocultar contraseña",
      },
      signUp: {
        signUp: "Registrarme",
        name: "Nombre",
        email: "Correo electrónico",
        emailExample: "ejemplo@ejemplo.com",
        password: "Contraseña",
        iAgree: "Acepto los",
        privacyPolicy: "Términos de Privacidad",
        createAccount: "Crear cuenta",
      },
      user: {
        users: "Usuarios",
        editMyProfile: "Editar mi perfil",
        groups: {
          groups: "Grupos",
        },
      },
      errorMessage: {
        required: "Este campo es requerido",
      },
    },
    baseList: {
      table: {
        columnName: {
          actions: "Acciones",
        },
      },
      pagination: {
        first: "Primero",
        last: "Ultimo",
        next: "Siguiente",
        previous: "Anterior",
        showing: "Mostrando",
        to: "de",
        of: "al",
        results: "resultados",
      },
    },
    btnLabels: {
      edit: "Editar",
      delete: "Eliminar",
      add: "Agregar",
      view: "Ver",
      goEnd: "Ir al final",
      goFirst: "Ir al inicio",
      cancel: "Cancelar",
      ok: "OK",
      update: "Modificar",
      save: "Guardar",
    },
    home: {
      title: "Inicio",
    },
    multiselect: {
      placeholder: "Seleccione una opción",
      selectLabel: "Presione enter para seleccionar",
    },
    forms: {
      userGroup: {
        title: "Grupo",
        titlePlural: "Grupos",
        addTitle: "Agregar @:forms.userGroup.title",
        editTitle: "Editar @:forms.userGroup.title",
        viewTitle: "Ver @:forms.userGroup.title",
        permissionsTitle: "Permisos",
        form: {
          name: "Nombre",
          deleteAllPermissions: "Eliminar todos",
          addAllPermissions: "Agregar todos",
        },
        fields: {
          id: "ID",
          name: "Nombre",
        },
        fieldsPlaceholder: {
          name: "Ingrese un nombre para el grupo",
        },
        tableColumns: {
          id: "ID",
          name: "Nombre",
        },
      },
      user: {
        title: "Usuario",
        addTitle: "Agregar @:forms.user.title",
        editTitle: "Editar @:forms.user.title",
        titlePlural: "Usuarios",
        fields: {
          username: "Usuario",
          email: "Correo electrónico",
          firstName: "Nombres",
          lastName: "Apellidos",
          password: "Contraseña",
          confirmPassword: "Confirmar contraseña",
          empresa: "Empresa",
          grupo: "Grupo",
        },
        fieldsPlaceholder: {
          username: "Ingrese el nombre del usuario",
          email: "Ingrese el correo electronico",
          firstName: "Ingrese el nombre del usuario",
          lastName: "Ingrese el apellido del usuario",
          password: "Ingrese la contraseña",
          confirmPassword: "Ingrese la nuevamente la contraseña",
          empresa: "Seleccione una empresa",
          group: "Seleccione un grupo",
        },
        tableColumns: {
          id: "id",
          username: "Usuario",
          email: "Correo electrónico",
          first_name: "Nombres",
          last_name: "Apellidos",
          empresa_nombre: "Empresa",
        },
      },
      negocio: {
        title: "Negocio",
        titlePlural: "Negocios",
        addTitle: "Agregar Negocio",
        editTitle: "Editar Negocio",
        fields: {
          descripcion: "Descripcion",
        },
        fieldsPlaceholder: {
          descripcion: "Ingrese el nombre del Negocio",
        },
        tableColumns: {
          id: "ID",
          descripcion: "Descripcion",
        },
      },
      segmento: {
        title: "Segmento",
        titlePlural: "Segmentos",
        addTitle: "Agregar Segmento",
        editTitle: "Editar Segmento",
        fields: {
          descripcion: "Descripcion",
        },
        fieldsPlaceholder: {
          descripcion: "Ingrese el nombre del segmento",
        },
        tableColumns: {
          id: "ID",
          descripcion: "Descripcion",
        },
      },
      origen: {
        title: "Origen",
        pluralTitle: "Origenes",
        addTitle: "Agregar Origen",
        editTitle: "Editar Origen",
        fields: {
          descripcion: "Descripcion",
        },
        fieldsPlaceholder: {
          descripcion: "Ingrese el nombre del origen",
        },
        tableColumns: {
          id: "ID",
          descripcion: "Descripcion",
        },
      },
      empresa: {
        title: "Empresa",
        titlePlural: "Empresas",
        addTitle: "Agregar Empresa",
        editTitle: "Editar Empresa",
        fields: {
          razonSocial: "Razón social",
          rucCi: "RUC o CI",
          direccion: "Dirección",
          negocio: "Negocio",
          segmento: "Segmento",
          origen: "Origen",
          cantSucursales: "Cantidad de Sucursales",
          cantEmpleados: "Cantidad de Empleados",
        },
        fieldsPlaceholder: {
          razonSocial: "Ingrese el nombre de la empresa",
          rucCi: "123456789-0",
          direccion: "Ingrese una dirección",
          negocio: "Seleccione un negocio",
          segmento: "Seleccione un segmento",
          origen: "Seleccione un origen",
          cantSucursales: "Ingrese la cantidad de sucursales",
          cantEmpleados: "Ingrese la cantidad de Empleados",
        },
        tableColumns: {
          id: "ID",
          razon_social: "Razón social",
          ruc_ci: "RUC o CI",
          direccion: "Dirección",
        },
      },
      seccion: {
        title: "Sección",
        addTitle: "Agregar Sección",
        editTitle: "Editar Sección",
        pluralTitle: "Secciones",
        descripcion: "Descripción",
        eje: "Eje",
        campoPertenece: "Campo al cual pertenece",
        tituloInforme: "Titulo en el Informe",
        preguntas: "Preguntas",
        fields: {
          descripcion: "Descripción",
        },
        fieldsPlaceholder: {
          descripcion: "Ingrese la descripción de la sección",
          eje: "Ingrese el nombre del eje",
          campoPertenece: "Ingrese el campo al cual pertenece",
          tituloInforme: "Ingrese el título en el Informe",
        },
        tableColumns: {
          id: "ID",
          descripcion: "Descripción",
        },
        modalPreguntas: {
          title: "Nueva Pregunta",
          descripcion: "Descripción",
          tipoRespuesta: "Tipo de respuesta",
          respuesta: "Respuesta",
          respuestas: "Respuestas",
          beneficio: "Beneficio",
          cerrada: "Cerrada",
          multiple: "Multiple",
          preguntaPadre: "Pregunta a la que está relacionada esta pregunta",
        },
      },
      encuesta: {
        title: "Encuesta",
        editTitle: "Editar Encuesta",
        pluralTitle: "Encuestas",
        completar: "Completar encuesta",
        fields: {
          descripcion: "Descripción",
          periodoActivo: "Periodo activo",
          idioma: "Idioma",
        },
        fieldsPlaceholder: {
          descripcion: "Ingrese la descripción de la encuesta",
          periodoActivo: "Seleccione un periodo activo",
          idioma: "Seleccione un idioma",
        },
      },
      encuestasCompuestas: {
        title: "Encuesta",
        editTitle: "Editar Encuesta",
        pluralTitle: "Encuestas Completas",
        completar: "Completar encuesta",
        fields: {
          descripcion: "Descripción",
          periodoActivo: "Periodo activo",
          idioma: "Idioma",
        },
        fieldsPlaceholder: {
          descripcion: "Ingrese la descripción de la encuesta",
          periodoActivo: "Seleccione un periodo activo",
          idioma: "Seleccione un idioma",
        },
      },
      idiomas: {
        title: "Idioma",
        addTitle: "Agregar Idioma",
        editTitle: "Editar Idioma",
        viewTitle: "Ver Idioma",
        pluralTitle: "Idiomas",
        fields: {
          descripcion: "Descripción",
        },
        multiselect: "Seleccione un idioma",
        fieldsPlaceholder: {
          descripcion: "Ingrese el descripción del idioma",
        },
        tableColumns: {
          id: "ID",
          descripcion: "Descripción",
        },
      },
      periodo: {
        title: "Periodo",
        addTitle: "Agregar Periodo",
        editTitle: "Editar Periodo",
        pluralTitle: "Periodos",
        fields: {
          fechaInicio: "Fecha de inicio",
          fechaFin: "Fecha de fin",
        },
        fieldsPlaceholder: {
          fechaInicio: "Ingrese la fecha de inicio del periodo",
          fechaFin: "Ingrese la fecha de fin del periodo",
        },
      },
      periodoActivo: {
        title: "Periodo Activo",
        addTitle: "Agregar Periodo Activo",
        editTitle: "Editar Periodo Activo",
        pluralTitle: "Periodos Activos",
        tableColumns: {
          id: "ID",
          fecha: "Fecha",
        },
        fields: {
          periodo: "Periodo",
        },
        fieldsPlaceholder: {
          periodo: "Ingrese el periodo",
          multiselect: "Seleccione un periodo activo",
        },
      },
      linkReportePorNegocio: {
        title: "Link de Reporte por Negocio",
        addTitle: "Agregar Link de Reporte por Negocio",
        editTitle: "Editar Link de Reporte por Negocio",
        pluralTitle: "Links de Reportes por Negocio",
        tableColumns: {
          id: "ID",
          negocio: "Negocio",
          url: "Url",
        },
        fields: {
          negocio: "Negocio",
          url: "Url",
        },
        fieldsPlaceholder: {
          negocio: "Ingrese el negocio",
          multiselect: "Seleccione un Link de Reporte por Negocio",
        },
      },
    },
    popup: {
      success: {
        title: "Operación exitosa",
        message: "La operación ha sido completada exitosamente",
      },
      error: {
        title: "Error",
        message: "Se ha producido un error antes de completar la operación",
      },
    },
  },
};

const i18n = createI18n({
  locale: 'es', // set locale
  fallbackLocale: 'es', // set fallback locale
  messages, // set locale messages
});

export default i18n;
