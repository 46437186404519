<template>
  <div class="container mx-auto px-4 h-full opacity-90">
    <Toast />
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
        >
          <div class="rounded-t mb-0 px-6 py-6">
            <div class="text-center">
              <h6 class="text-blueGray-500 text-sm font-bold">
                {{ $t("message.login.login") }}
              </h6>
            </div>
          </div>
          <div class="flex-auto px-4 lg:px-10 py-10 pt-2">
            <form @submit.prevent="login">
              <div class="flex text-center w-full mb-3">
                <span v-if="error.show" class="w-full text-red-500">
                  {{ error.message }}
                </span>
              </div>
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  {{ $t("message.login.username") }}
                </label>
                <input
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  :placeholder="$t('message.login.username')"
                  v-model="userData.username"
                />
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  {{ $t("message.login.password") }}
                </label>
                <input
                  :type="showPassword ? 'text' : 'password'"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  :placeholder="$t('message.login.password')"
                  @keydown.enter.prevent="login"
                  autocomplete="on"
                  v-model="userData.password"
                />
              </div>
              <div class="relative w-full mb-3">
                <button
                  class="py-3 rounded text-xs text-blue-500 hover:text-blue-800 focus:outline-none focus:ring"
                  @click.prevent="togglePasswordVisibility"
                >
                  {{
                    showPassword
                      ? $t("message.login.hiddePassword")
                      : $t("message.login.showPassword")
                  }}
                </button>
              </div>
              <div class="relative w-full">
                <button
                  class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="submit"
                >
                  {{ $t("message.login.signIn") }}
                </button>
              </div>
            </form>
          </div>
        </div>
        <!-- <div class="flex flex-wrap mt-6 relative">
          <div class="w-1/2">
            <router-link to="/auth/forgot_password" class="text-blueGray-200">
              <small>{{ $t("message.login.forgotPassword") }}?</small>
            </router-link>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      userData: {
        username: null,
        password: null,
      },
      showPassword: false,
      error: {
        show: false,
        message: "",
      },
    };
  },
  methods: {
    async login() {
      try {
        await this.$store.dispatch("login", this.userData);
      } catch (err) {
        this.error = {
          show: true,
          message: err.message,
        };
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  },
  watch: {
    userData: {
      deep: true,
      handler() {
        this.error = { show: false, message: "" };
        this.$forceUpdate();
      },
    },
  },
};
</script>
