import { createWebHistory, createRouter } from "vue-router";
import store from '@/store';

// layouts

import Admin from "@/layouts/Admin.vue";
import Auth from "@/layouts/Auth.vue";

// views for Admin layout

import Dashboard from "@/views/admin/Dashboard.vue";
import Settings from "@/views/admin/Settings.vue";
import Tables from "@/views/admin/Tables.vue";
import Maps from "@/views/admin/Maps.vue";

// views for Auth layout

import Login from "@/views/auth/Login.vue";
import Register from "@/views/auth/Register.vue";
import ForgotPassword from "@/views/auth/ForgotPassword.vue";
import VerificationCode from "@/views/auth/VerificationCode.vue";
import ResetPassword from "@/views/auth/ResetPassword.vue";

// view for users layout
import BaseLayout from "@/layouts/BaseLayout.vue";
import ListUserGroups from "@/views/users/ListUserGroups.vue";
import ListUsers from "@/views/users/ListUsers.vue";
import CrudUserGroup from "@/views/users/CrudUserGroup.vue";
import CrudUser from "@/views/users/CrudUser.vue";
import UpdateUserPassword from "@/views/users/UpdateUserPassword.vue";

// View for business layout
import ListNegocios from '@/views/negocios/ListNegocios.vue';
import CrudNegocio from '@/views/negocios/CrudNegocio.vue'

import CrudOrigen from "@/views/origenes/CrudOrigen.vue";
import ListOrigenes from "@/views/origenes/ListOrigenes.vue";

// View for company layout
import ListEmpresas from "@/views/empresas/ListEmpresas.vue";
import CrudEmpresa from '@/views/empresas/CrudEmpresa.vue';

import CrudPeriodo from '@/views/periodo/CrudPeriodo.vue';
import ListPeriodo from '@/views/periodo/ListPeriodo.vue';

import CrudIdioma from '@/views/idiomas/CrudIdiomas.vue';
import ListIdioma from '@/views/idiomas/ListIdiomas.vue';

import CrudPeriodoActivo from '@/views/periodoActivo/CrudPeriodoActivo.vue';
import ListPeriodoActivo from '@/views/periodoActivo/ListPeriodoActivo.vue'

// View for survey layout
import CrudEncuesta from '@/views/encuestas/CrudEncuesta.vue';
import ListEncuesta from '@/views/encuestas/ListEncuesta.vue'
import CrudSeccionRefactor from '@/views/secciones/CrudSeccionRefactor.vue'
import ListSecciones from '@/views/secciones/ListSecciones.vue'

import CompletarEncuesta from '@/views/completarEncuestas/CompletarEncuesta.vue'
import ListaEncuestasCompletas from '@/views/completarEncuestas/ListaEncuestasCompletas.vue'

import ListaLinkReportePorNegocio from '@/views/reportesPorNegocio/ListaLinkReportePorNegocio.vue'
import CrudLinkReportePorNegocio from '@/views/reportesPorNegocio/CrudLinkReportePorNegocio.vue'

// views without layouts

import Landing from "@/views/Landing.vue";
import Profile from "@/views/Profile.vue";
// import Index from "@/views/Index.vue";

import ReportePBI from "@/views/reportesPBI/reportepbi.vue";


// routes

const routes = [
  {
    path: "/admin",
    redirect: "/admin/dashboard",
    component: Admin,
    children: [
      {
        path: "/admin/dashboard",
        component: Dashboard,
        name: "Home",
      },
      {
        path: "/admin/settings",
        component: Settings,
      },
      {
        path: "/admin/tables",
        component: Tables,
      },
      {
        path: "/admin/maps",
        component: Maps,
      },
    ],
  },
  {
    path: "/auth",
    redirect: "/auth/login",
    component: Auth,
    children: [
      {
        path: "/auth/login",
        name: "Login",
        component: Login,
      },
      {
        path: "/auth/register",
        component: Register,
      },
      {
        path: "/auth/forgot_password",
        component: ForgotPassword,
      },
      {
        path: "/auth/verification_code",
        component: VerificationCode,
      },
      {
        path: "/auth/reset_password/:uid/:token",
        component: ResetPassword,
      },
    ],
  },
  {
    path: "/groups",
    component: BaseLayout,
    children: [
      {
        path: "/groups",
        name: "ListUserGroups",
        component: ListUserGroups,
      },
      {
        path: "/groups/add",
        name: "AddUserGroup",
        component: CrudUserGroup,
      },
      {
        path: "/groups/:id",
        name: "EditUserGroup",
        component: CrudUserGroup,
      },
      {
        path: "/groups/:id/view",
        name: "ViewUserGroup",
        component: CrudUserGroup,
      },
    ],
  },
  {
    path: "/users",
    component: BaseLayout,
    children: [
      {
        path: "/users",
        name: "ListUsers",
        component: ListUsers,
      },
      {
        path: "/users/add",
        name: "AddUser",
        component: CrudUser,
      },
      {
        path: "/users/:id",
        name: "EditUser",
        component: CrudUser,
      },
      {
        path: "/users/:id/view",
        name: "ViewUser",
        component: CrudUser,
      },
      {
        path: "/users/change_password",
        name: "UpdateUserPassword",
        component: UpdateUserPassword,
      },
    ],
  },
  {
    path: "/origenes",
    component: BaseLayout,
    children: [
      {
        path: "/origenes",
        name: "ListOrigenes",
        component: ListOrigenes,
        meta: {
          breadcrumb: "Lista de Origenes",
        },
      },
      {
        path: "/origenes/add",
        name: "AddOrigen",
        component: CrudOrigen,
      },
      {
        path: "/origenes/:id",
        name: "EditOrigen",
        component: CrudOrigen,
      },
      {
        path: "/origenes/:id/view",
        name: "ViewOrigen",
        component: CrudOrigen,
      },
    ],
  },
  {
    path: "/negocios",
    component: BaseLayout,
    children: [
      {
        path: "/negocios",
        name: "ListNegocios",
        component: ListNegocios,
        meta: {
          breadcrumb: "Lista de negocios",
        },
      },
      {
        path: "/negocios/add",
        name: "AddNegocio",
        component: CrudNegocio,
      },
      {
        path: "/negocios/:id",
        name: "EditNegocio",
        component: CrudNegocio,
      },
      {
        path: "/negocios/:id/view",
        name: "ViewNegocio",
        component: CrudNegocio,
      },
    ],
  },
  {
    path: "/idiomas",
    component: BaseLayout,
    children: [
      {
        path: "/idiomas",
        name: "ListIdiomas",
        component: ListIdioma,
        meta: {
          breadcrumb: "Lista de idiomas",
        },
      },
      {
        path: "/idiomas/add",
        name: "AddIdioma",
        component: CrudIdioma,
      },
      {
        path: "/idiomas/:id",
        name: "EditIdioma",
        component: CrudIdioma,
      },
      {
        path: "/idiomas/:id/view",
        name: "ViewIdioma",
        component: CrudIdioma,
      },
    ],
  },
  {
    path: "/empresas",
    component: BaseLayout,
    children: [
      {
        path: "/empresas",
        name: "ListEmpresas",
        component: ListEmpresas,
        meta: {
          breadcrumb: "Lista de empresas",
        },
      },
      {
        path: "/empresas/add",
        name: "AddEmpresa",
        component: CrudEmpresa,
      },
      {
        path: "/empresas/:id",
        name: "EditEmpresa",
        component: CrudEmpresa,
      },
      {
        path: "/empresas/:id/view",
        name: "ViewEmpresa",
        component: CrudEmpresa,
      },
    ],
  },
  {
    path: "/periodos",
    component: BaseLayout,
    children: [
      {
        path: "/periodos",
        name: "ListPeriodos",
        component: ListPeriodo,
        meta: {
          breadcrumb: "Lista de periodos",
        },
      },
      {
        path: "/periodos/add",
        name: "AddPeriodo",
        component: CrudPeriodo,
      },
      {
        path: "/periodos/:id",
        name: "EditPeriodo",
        component: CrudPeriodo,
      },
      {
        path: "/periodos/:id/view",
        name: "ViewPeriodo",
        component: CrudPeriodo,
      },
    ],
  },
  {
    path: "/periodos_activos",
    component: BaseLayout,
    children: [
      {
        path: "/periodos_activos",
        name: "ListPeriodosActivos",
        component: ListPeriodoActivo,
        meta: {
          breadcrumb: "Lista de periodos activos",
        },
      },
      {
        path: "/periodos_activos/add",
        name: "AddPeriodoActivo",
        component: CrudPeriodoActivo,
      },
      {
        path: "/periodos_activos/:id",
        name: "EditPeriodoActivo",
        component: CrudPeriodoActivo,
      },
      {
        path: "/periodos_activos/:id/view",
        name: "ViewPeriodoActivo",
        component: CrudPeriodoActivo,
      },
    ],
  },
  {
    path: "/encuestas",
    component: BaseLayout,
    children: [
      {
        path: "",
        name: "ListEncuesta",
        component: ListEncuesta,
      },
      {
        path: "add",
        name: "AddEncuesta",
        component: CrudEncuesta,
      },
      {
        path: ":id",
        name: "EditEncuesta",
        component: CrudEncuesta,
      },
      {
        path: ":id/view",
        name: "ViewEncuesta",
        component: CrudEncuesta,
      },
    ],
  },
  {
    path: "/secciones",
    component: BaseLayout,
    children: [
      {
        path: "",
        name: "ListSecciones",
        component: ListSecciones,
      },
      {
        path: "add",
        name: "AddSeccion",
        component: CrudSeccionRefactor,
      },
      {
        path: ":id",
        name: "EditSeccion",
        component: CrudSeccionRefactor,
      },
      {
        path: ":id/view",
        name: "ViewSeccion",
        component: CrudSeccionRefactor,
      },
    ],
  },
  {
    path: "/completar_encuesta",
    component: BaseLayout,
    children: [
      {
        path: "",
        name: "ListaEncuestasCompletas",
        component: ListaEncuestasCompletas,
      },
      {
        path: "add",
        name: "AddCompletarEncuesta",
        component: CompletarEncuesta,
      },
      {
        path: ":id",
        name: "EditEncuestaCompleta",
        component: CompletarEncuesta,
      },
      {
        path: ":id/view",
        name: "ViewEncuestaCompleta",
        component: CompletarEncuesta,
      },
    ],
  },
  {
    path: "/reportes",
    component: BaseLayout,
    children: [
      {
        path: "pbi",
        name: "Reportes",
        component: ReportePBI,
      },
    ],
  },
  {
    path: "/link_reporte",
    component: BaseLayout,
    children: [
      {
        path: "",
        name: "LinksReportePorNegocico",
        component: ListaLinkReportePorNegocio,
      },
      {
        path: "add",
        name: "AddLinkReportePorNegocio",
        component: CrudLinkReportePorNegocio,
      },
      {
        path: ":id",
        name: "EditLinkReportePorNegocio",
        component: CrudLinkReportePorNegocio,
      },
      {
        path: ":id/view",
        name: "ViewLinkReportePorNegocio",
        component: CrudLinkReportePorNegocio,
      },
    ],
  },
  {
    path: "/landing",
    component: Landing,
  },
  {
    path: "/profile",
    component: Profile,
  },
  {
    path: "/",
    component: BaseLayout,
    name: "Inicio",
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (from.fullPath !== to.fullPath) {
    store.state.page = 1
  }
  next()
})

export default router