import PeriodoActivoService from "@/api/encuestas/periodo_activo";

const PeriodoActivoStore = {
  state: {
    periodoActivoList: [],
    form: {},
  },
  mutations: {
    SET_PERIODO_ACTIVO_LIST: (state, periodoActivoList) => (state.periodoActivoList = periodoActivoList),
    SET_PERIODO_ACTIVO: (state, periodo_activo) => (state.form = periodo_activo),
  },
  actions: {
    async getPeriodoActivoList({ dispatch, getters }, { search, page, activo, pageSize }) {
      const lang = getters.getLang;
      const res = await PeriodoActivoService.list(search, activo, page, pageSize, lang);

      switch (res?.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    },
    async savePeriodoActivo({ state, dispatch }) {
      const res = state.form.id
        ? await PeriodoActivoService.edit(state.form)
        : await PeriodoActivoService.create(state.form);

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    },
    async getPeriodoActivoById({ commit, dispatch }, { id }) {
      const res = await PeriodoActivoService.getById({ id });
      let data = null;

      switch (res.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          data = await res.json();
          commit("SET_PERIODO_ACTIVO", data);

          return data;
      }
    },
    setPeriodoActivoList({ commit }, list) {
      commit("SET_PERIODO_ACTIVO_LIST", list);
    },
    setPeriodoActivo({ commit }, payload) {
      commit("SET_PERIODO_ACTIVO", payload);
    },
    async deactivatePeriodoActivo({ dispatch }, { id }) {
      const res = await PeriodoActivoService.deactivate({ id });

      switch (res?.status) {
        case 401:
          dispatch("redirect", "/auth/login");
          break;
        case 404:
          dispatch("redirect", "/");
          break;
        default:
          return res;
      }
    },
  },
  getters: {
    getPeriodoActivoListState: (state) => state.periodoActivoList,
  },
};

export default PeriodoActivoStore;
