import Coockies from "js-cookie";
import Base from "../base";

export default class SeccionServices extends Base {
  static endpointUrl = "encuestas/";

  static async getEncuestaUsuarioEmpresa() {
    const token = Coockies.get("_riart_accessToken");
    const url = new URL(`${process.env.VUE_APP_API}${this.endpointUrl}empresa/`);

    if (token) {
      const headers = { Authorization: `Bearer ${token}` };

      return await fetch(url, { headers: headers });
    } else {
      throw new Error("No se encontró el token");
    }
  }

  static async guardarEncuestaUsuarioEmpresa({encuesta, idEncuesta}) {
    const token = Coockies.get("_riart_accessToken");
    const url = new URL(`${process.env.VUE_APP_API}${this.endpointUrl}usuario_empresa/${idEncuesta ?? ''}${idEncuesta ? '/' : ''}`);

    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const method = idEncuesta ? "PATCH" : "POST"

      return await fetch(url, {
        method: method,
        headers: headers,
        body: JSON.stringify(encuesta),
      });
    } else {
      throw new Error("No se encontró el token");
    }
  }

  static async descargarReporteEncuestasCompletasCSV(encuestaId) {
    const token = Coockies.get("_riart_accessToken");
    const baseUrl = `${process.env.VUE_APP_API}reportes/descargar_reporte_encuestas_completas_csv/`;
    const finalUrl = encuestaId ? `${baseUrl}${encuestaId}/` : baseUrl;
    const url = new URL(finalUrl);

    if (!token) {
      throw new Error("No se encontró el token");
    }

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    return await fetch(url, {
      method: "GET",
      headers: headers,
    });
  }
}
