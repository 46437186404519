<template>
  <div
    v-if="isVisible"
    class="flex flex-col h-full w-full overflow-y-auto pb-2"
  >
    <h2 class="text-blueGray-600 font-bold py-4 uppercase">
      {{ seccion.descripcion }}
    </h2>
    <div class="w-full px-2">
      <div
        v-for="(pregunta, idx) in preguntasFiltradas"
        :key="`${pregunta.id}`"
        class="col-span-1 pl-2 pr-2"
      >
        <Pregunta
          :key="pregunta.id"
          :index="idx"
          :pregunta="pregunta"
          :respuesta-seleccionada="getRespuestaSeleccionada(pregunta)"
          :is-only-view="isOnlyView"
          :is-disabled="isDisabled(pregunta)"
          @update-respuesta="emitirRespuesta"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Pregunta from "@/components/Wizard/Pregunta.vue";
import { baseMixin } from "@/mixins/base";

export default {
  name: "WizardStep",
  mixins: [baseMixin],
  components: {
    Pregunta,
  },
  props: {
    stepNumber: {
      type: Number,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
    },
    seccion: {
      type: Object,
      required: true,
    },
    respuestas: {
      type: Object,
      required: true,
    },
    isOnlyView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      respuestaSeleccionada: { ...this.respuestas },
    };
  },
  computed: {
    isVisible() {
      return this.stepNumber === this.currentStep;
    },
    preguntasFiltradas() {
      return this.seccion.preguntas.filter(
        (pregunta) => !this.ocultar(pregunta)
      );
    },
  },
  methods: {
    getRespuestaSeleccionada(pregunta) {
      const sel = this.respuestaSeleccionada.preguntas.find(
        (pr) => pr.id === pregunta.id
      );
      if (!sel || !sel.respuesta) {
        return null;
      }
      // Para tipo 1 y no múltiple, si la respuesta es numérica, buscar el objeto completo
      if (pregunta.tipo_respuesta === 1 && !pregunta.multiple) {
        if (typeof sel.respuesta === "number") {
          const fullOption = pregunta.respuestas.find(
            (r) => r.id === sel.respuesta
          );
          return fullOption || null;
        }
        // Si ya es objeto, retornarlo
        return sel.respuesta;
      }
      // Para tipo 3 u otros, retornar la respuesta tal cual
      return sel.respuesta;
    },
    emitirRespuesta({ idx, pregunta, respuesta }) {
      let respuestasModificadas = this.respuestas;

      respuestasModificadas.preguntas[idx] = {
        id: pregunta.id,
        tipo_respuesta: pregunta.tipo_respuesta,
        respuesta: respuesta,
      };

      this.$emit("update:respuestas", respuestasModificadas);
      this.$forceUpdate();
    },
    ocultar(pregunta) {
      // Si la pregunta tiene padre, se debe ocultar si la respuesta del padre es "NO APLICA"
      if (pregunta.padre) {
        const padrePregunta = this.seccion.preguntas.find(
          (pr) => pr.id === pregunta.padre
        );
        if (!padrePregunta) {
          return false;
        }
        const respuestaPadre = this.respuestaSeleccionada.preguntas.find(
          (pr) => pr.id === padrePregunta.id
        );
        if (respuestaPadre && respuestaPadre.respuesta) {
          let descripcion = "";
          if (
            typeof respuestaPadre.respuesta === "object" &&
            respuestaPadre.respuesta.descripcion != null
          ) {
            descripcion = respuestaPadre.respuesta.descripcion;
          } else {
            descripcion = respuestaPadre.respuesta;
          }
          // Forzar que "descripcion" sea una cadena
          if (typeof descripcion !== "string") {
            descripcion = String(descripcion);
          }
          return descripcion.trim().toUpperCase() === "NO APLICA";
        }
      }
      return false;
    },
    isDisabled(pregunta) {
      if (pregunta.padre) {
        const padrePregunta = this.seccion.preguntas.find(
          (pr) => pr.id === pregunta.padre
        );

        if (!padrePregunta) {
          return false;
        }

        const respuestaPadre = this.respuestaSeleccionada.preguntas.find(
          (pr) => pr.id === padrePregunta.id
        );

        if (
          respuestaPadre?.respuesta?.descripcion &&
          padrePregunta.tipo_respuesta === 3
        ) {
          return (
            respuestaPadre.respuesta.descripcion.toUpperCase() === "NO APLICA"
          );
        }
      }

      return false;
    },
  },
  watch: {
    respuestas: {
      handler(newVal) {
        this.respuestaSeleccionada = { ...newVal };
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
/* Estilos específicos del paso */
.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px; /* Espacio entre columnas */
}
</style>
