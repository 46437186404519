import Base from "../base";
import Coockies from "js-cookie";
import { PAGE_SIZE } from "@/utils/constants";
const COOKIE_NAME = "_riart_accessToken";

export default class PeriodoActivoServices extends Base {
  static endpointUrl = "encuestas/periodos_activos/";

  static async list(search, activo, page, pageSize, lang) {
    const token = Coockies.get(COOKIE_NAME);
    const url = new URL(`${process.env.VUE_APP_API}${this.endpointUrl}`);

    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      search ? url.searchParams.append("search", search) : null;
      url.searchParams.append("lang", lang ?? "es");
      url.searchParams.append("page", page ?? 1);
      url.searchParams.append("page_size", pageSize ?? PAGE_SIZE);

      if (activo !== null) {
        url.searchParams.append("activo", activo);
      }

      return await fetch(url, {
        headers: headers,
      });
    } else {
      throw new Error("No se encontró el token");
    }
  }
}
