<template>
  <div>
    <li v-if="hasPermission(permissionName)" class="items-center">
      <router-link 
        :to="routeName" 
        v-slot="{ isActive }"
        class="sidebar-menu text-xs uppercase py-2 font-bold block"
        :class="[
          isActive
            ? 'text-blueGray-500 hover:text-blueGray-500'
            : 'color-secondary-500 hover:color-secondary-500',
        ]"

      >
        <i
          class="fas fa-user-group text-sm px-4"
          :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
        ></i>
        <span class="px-3">{{ title }}</span>

      </router-link>
    </li>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  props: {
    permissionName: {
      type: String,
      required: true,
    },
    routeName: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapActions(['hasCurrentUserPermission']),

    async hasPermission(code) {
      const hasPerm = await this.hasCurrentUserPermission(code);
      return hasPerm
    },
  },
};
</script>
<style scoped>
::v-deep .sidebar-menu:hover {
  background-color: #f1f5f9 !important;
  font-weight: bold;
}
</style>