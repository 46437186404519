<template>
  <crud-base
    :title="title"
    :popup-data="popupData"
    :show-popup="showPopup"
    @cancel="returnToList()"
    @add-register="save"
    @ok="popupData.type === 'error' ? (showPopup = false) : returnToList()"
  >
    <template v-slot:form>
      <div class="flex-auto lg:px-2 py-2 pt-4">
        <form>
          <div class="flex flex-wrap py-2">
            <div
              class="w-full lg:w-6/12 px-2"
              :class="{
                'text-red-500 text-xs italic font-weight-bold mt-1':
                  !isValidTipoUsuario,
              }"
            >
              <label
                class="w-full block text-blueGray-600 text-xs font-bold mb-2"
              >
                {{ `Tipo de usuario` }}
              </label>
              <multiselect
                v-model="link_reporte.tipo_usuario"
                placeholder="Seleccione el tipo de usuario"
                label="descripcion"
                :options="tiposUsuariosList"
              />
              <label class="typo__label form__label" v-if="!isValidTipoUsuario">{{
                errors?.tipo_usuario.join("")
              }}</label>
            </div>
            <div
              v-if="link_reporte?.tipo_usuario?.descripcion !== 'Administrador'"
              class="w-full lg:w-6/12 px-2"
            >
              <label class="w-full block text-blueGray-600 text-xs font-bold mb-2">
                {{ $t("forms.negocio.title") }}
              </label>
              <negocio-multiselect
                v-model="link_reporte.rubro"
                @update-rubro="onRubroChange"
              />
            </div>
          </div>
          <div class="flex flex-wrap py-2">
            <div class="w-full px-2">
              <div class="relative w-full mb-3">
                <label
                  class="block text-blueGray-600 text-xs font-bold mb-2"
                  for="url"
                >
                  {{ `Url` }}
                </label>
                <textarea
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  rows="4"
                  v-model="link_reporte.url"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </template>
  </crud-base>
</template>
<script>
import CrudBase from "@/components/Cards/CrudBase.vue";
import NegocioMultiselect from "@/views/negocios/NegocioMultiselect.vue";
import { baseMixin } from "@/mixins/base";
import { mapActions } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  components: {
    CrudBase,
    NegocioMultiselect,
    Multiselect
  },
  data() {
    return {
      module: "linkReportePorNegocio",
      capitalizeModule: "Link de reporte por negocio",
      listName: "LinksReportePorNegocico",
      link_reporte: {
        tipo_usuario: null,
        rubro: null,
        url: "",
      },
      tiposUsuariosList: [
        { descripcion: "Administrador" },
        { descripcion: "Usuario"}
      ]
    };
  },
  mixins: [baseMixin],
  async created() {
    this.isLoading = !this.isLoading;
    this.setShowOverlay(this.isLoading);
    this.getPageTitle();

    // load data if edit or only read view
    // else do nothing
    this.$route.params.id ? await this.loadData() : null;

    this.isLoading = !this.isLoading;
    this.setShowOverlay(this.isLoading);
  },
  computed: {
    isValidTipoUsuario() {
      return this.link_reporte.tipo_usuario !== null;
    }
  },
  methods: {
    ...mapActions([
      "getLinkReportePorNegocioById",
      "setShowOverlay",
      "getNegocioList",
      "setNegocioList",
      "setShowOverlay",
      "saveLinkReportePorNegocio",
    ]),
    async save() {
      if (this.link_reporte.rubro === null) {
        this.popupData = {
          show: true,
          type: "error",
          title: this.$t("popup.error.title"),
          message: "Debe seleccionar un negocio",
        };
        this.showPopup = true;
      }
      if (this.link_reporte.url === "") {
        this.popupData = {
          show: true,
          type: "error",
          title: this.$t("popup.error.title"),
          message: "Debe ingresar una url",
        };
        this.showPopup = true;
      }

      try {
        const form = {
          tipo_usuario: this.link_reporte?.tipo_usuario?.descripcion,
          rubro: this.link_reporte?.rubro?.id,
          url: this.link_reporte?.url,
        }
        if (this.$route.params.id) {
          form.id = this.$route.params.id
        }
        this.setShowOverlay(true);
        const res = await this.saveLinkReportePorNegocio(form);
        const data = await res.json();

        this.setShowOverlay(false);

        this.popupData = {
          show: true,
          type: res.status >= 200 && res.status <= 300 ? "success" : "error",
          title:
            res.status >= 200 && res.status <= 300
              ? this.$t("popup.success.title")
              : this.$t("popup.error.title"),
          message: data?.non_field_errors
            ? data.non_field_errors[0]
            : this.$t("popup.success.message"),
        };
        this.showPopup = true;
      } catch (error) {
        this.setShowOverlay(false);
        this.popupData = {
          show: true,
          type: "error",
          title: this.$t("popup.error.title"),
          message: this.$t("popup.error.message"),
        };
        this.showPopup = true;
      } finally {
        this.setShowOverlay(false)
      }
    },
    async loadData() {
      this.link_reporte = await this.getLinkReportePorNegocioById({
        id: this.$route.params.id,
      });
      this.link_reporte.tipo_usuario = { descripcion: this.link_reporte.tipo_usuario }
      await this.findNegocio(this.link_reporte.rubro);
    },
    async findNegocio(query) {
      try {
        const res = await this.getNegocioList({ search: query, page: 1 });
        const { results } = await res.json();
        this.setNegocioList(results);

        this.link_reporte = {
          ...this.link_reporte,
          rubro: results.filter((r) => r.id === query)[0],
        };
        this.$forceUpdate();
      } catch (err) {
        this.popupData = {
          type: "error",
          message: err,
        };
        this.showPopup = true;
      }
    },
  },
};
</script>
