import Coockies from "js-cookie";
import Base from "../base";


export default class LinkReporteServices extends Base {
  static endpointUrl = "reportes/link_reporte/";

  static async getLinkReporte() {
    const token = Coockies.get("_riart_accessToken");
    const url = new URL(`${process.env.VUE_APP_API}${this.endpointUrl}get_reporte/`);

    if (token) {
      const headers = { Authorization: `Bearer ${token}` };
      return await fetch(url, {headers: headers})
    } else {
      throw new Error("No se encontró el token");
    }
  }
}
