<template>
  <div class="flex flex-col w-full">
    <div class="flex w-full px-2 justify-between">
      <label
        class="block uppercase text-blueGray-600 text-xs font-bold py-2 mr-2"
      >
        {{ $t('forms.seccion.modalPreguntas.respuesta') }} {{`${index + 1}`}}
      </label>
      <button 
        v-if="isOnlyView"
        @click.prevent="$emit('remove-respuesta', index)">
        <i class="fa fa-minus"></i>
      </button>
    </div>
    <div class="flex w-full flex-col">
      <div class="flex w-full">
        <div class="w-full px-2">
          <custom-input 
            label=""
            v-model="descripcion"
            :disabled="!isOnlyView"
          />
        </div>
      </div>
    </div>
    <hr
      v-if="!isLastItem" 
      :style="{ borderTopWidth: '2px'}"/>
  </div>
</template>
<script>
import CustomInput from "@/components/Inputs/CustomInput";

export default {
  components: {
    CustomInput,
  },
  props: {
    respuesta: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    isLastItem: {
      type: Boolean,
      required: true,
      default: true
    },
    isOnlyView: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      respuestaLocal: { ...this.respuesta },
      tipoRespuestaList: [],
    };
  },
  computed: {
    descripcion: {
      get() {
        return this.respuesta.descripcion;
      },
      set(value) {
        this.$emit('update-respuesta', {
          index: this.index,
          descripcion: value,
        });
      },
    },
  },
};
</script>
