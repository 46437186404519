<template>
  <div>
    <p class="uppercase text-sm font-bold text-justify py-2">
      <span class="mr-2">{{ index + 1 }}.</span>
      {{ pregunta.descripcion }}
    </p>
    <ul
      v-if="
        (pregunta.tipo_respuesta === 1 && pregunta.multiple) ||
        pregunta.tipo_respuesta === 2
      "
    >
      <li
        class="ml-3 py-1"
        v-for="respuesta in pregunta.respuestas"
        :key="respuesta.id"
      >
        <input
          type="checkbox"
          class="border-0 px-3 py-3 placeholder-blueGray-300 uppercase text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150 mr-2 ml-3"
          :value="respuesta.id"
          :checked="isChecked(respuesta)"
          :disabled="isOnlyView || isDisabled"
          @change="toggleCheckbox(respuesta.id)"
        />
        <label>{{ capitalizeText(respuesta.descripcion) }}</label>
      </li>
    </ul>
    <multiselect
      v-else-if="pregunta.tipo_respuesta === 1 && !pregunta.multiple"
      v-model="selectedOption"
      :options="parsedRespuestas"
      :multiple="false"
      :nullable="true"
      placeholder="Seleccione una opción"
      label="descripcion"
      track-by="id"
    />
    <div class="flex w-full" v-else>
      <input
        v-model="localInput"
        :type="getInputType(pregunta.tipo_respuesta)"
        :placeholder="getPlaceholder(pregunta.tipo_respuesta)"
        :disabled="isOnlyView || isDisabled"
        class="w-full border-0 px-3 py-3 placeholder-blueGray-300 uppercase text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150 mr-2 ml-3"
        @input="emitirRespuesta"
      />
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "Pregunta",
  components: { Multiselect },
  props: {
    index: {
      type: Number,
      required: true,
    },
    pregunta: {
      type: Object,
      required: true,
    },
    respuestaSeleccionada: {
      type: [String, Number, Array, Object],
      default: null,
    },
    isOnlyView: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    parsedRespuestas() {
      if (!this.pregunta.respuestas) return [];
      return this.pregunta.respuestas
        .filter((item) => item && item.id != null)
        .map((item) => ({
          id: item.id,
          descripcion: item.descripcion || item.respuesta,
          ...item,
        }));
    },
    selectedOption: {
      get() {
        // Para preguntas de selección única (tipo 1 y !multiple)
        if (this.pregunta.tipo_respuesta === 1 && !this.pregunta.multiple) {
          // Si respuestaSeleccionada es un objeto y tiene id, lo usamos
          if (
            this.respuestaSeleccionada &&
            typeof this.respuestaSeleccionada === "object"
          ) {
            if (Array.isArray(this.respuestaSeleccionada)) {
              return this.respuestaSeleccionada[0];
            } else {
              return this.respuestaSeleccionada;
            }
          }
          // Si respuestaSeleccionada es un número o cadena,
          // buscamos en parsedRespuestas el objeto correspondiente
          const result = this.parsedRespuestas.find(
            (item) => item.id == this.respuestaSeleccionada
          );
          return result ? result : {};
        }
        // Para otros tipos, si respuestaSeleccionada es null retornamos {}
        return this.respuestaSeleccionada ? this.respuestaSeleccionada : {};
      },
      set(val) {
        this.$emit("update-respuesta", {
          idx: this.index,
          pregunta: this.pregunta,
          respuesta: val,
        });
      }
    },
    localInput: {
      get() {
        return this.respuestaSeleccionada;
      },
      set(val) {
        this.$emit("update-respuesta", {
          idx: this.index,
          pregunta: this.pregunta,
          respuesta: val,
        });
      },
    },
  },
  methods: {
    capitalizeText(value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0)?.toUpperCase() + value.slice(1)?.toLowerCase();
    },
    getInputType(tipo_respuesta) {
      let type = "text";
      switch (tipo_respuesta) {
        case 3:
          type = "text";
          break;
        case 2:
          type = "number";
          break;
        case 1:
          type = "checkbox";
          break;
        default:
          type = tipo_respuesta.toLowerCase();
          break;
      }
      return type;
    },
    getPlaceholder(tipo_respuesta) {
      switch (tipo_respuesta) {
        case "TEXT":
          return "Ingrese su respuesta";
        case "NUMBER":
          return "Ingrese un número";
        default:
          return "";
      }
    },
    isChecked(respuesta) {
      if (Array.isArray(this.respuestaSeleccionada)) {
        return this.respuestaSeleccionada.includes(respuesta.id);
      }
      return false;
    },
    toggleCheckbox(respuestaId) {
      // Trabajamos con una copia del array para evitar mutar directamente el prop
      let respuestaLocal = Array.isArray(this.respuestaSeleccionada)
        ? [...this.respuestaSeleccionada]
        : [];
      const index = respuestaLocal.indexOf(respuestaId);
      if (index > -1) {
        respuestaLocal.splice(index, 1);
      } else {
        respuestaLocal.push(respuestaId);
      }
      this.$emit("update-respuesta", {
        idx: this.index,
        pregunta: this.pregunta,
        respuesta: respuestaLocal,
      });
    },
    emitirRespuesta() {
      this.$emit("update-respuesta", {
        idx: this.index,
        pregunta: this.pregunta,
        respuesta:
          this.pregunta.tipo_respuesta === 1 && !this.pregunta.multiple
            ? this.selectedOption
            : this.respuestaSeleccionada,
      });
    },
  },
};
</script>
